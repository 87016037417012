import React from 'react'
import Aux from '../../hoc/Aux'

import VCCList from '../VCCList/VCCList'

const VCC = (props) => {
  const listVcc = props.vccItem.map((item, index)=>{
    return(
      <VCCList key={item.id + index} id={item.id} classify={'vcc'+ [index+1]} click={props.gotoVCC} uploadUrl={props.uploadUrl} img={item.image} content={item.caption} />
    )
  })
  return listVcc;
}

export default VCC
