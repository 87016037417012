import * as actionTypes from '../actions/actionTypes'

// import {updateObject} from '../utility'

import {checkValidity, updateFeedback} from '../utility'

import Cookies from 'universal-cookie'

const cookies = new Cookies();

const initialState = {
  dataInterest: {
    email:{
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: 'Email Address'
      },
      value: '',
      validation: {
          required: true,
          isEmail: true
      },
      valid: false,
      touched: false,
      feedback: "This field is required"
    },
    phone:{
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: 'Phone Number'
      },
      value: '',
      validation: {
        required: true,
        isNumeric: true
      },
      valid: false,
      touched: false,
      feedback: "This field is required"
    },
    firstName:{
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: 'Firstname'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      feedback: "This field is required"
    },
    lastName:{
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: 'Lastname'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      feedback: "This field is required"
    },
    highschoolName:{
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: 'Name'
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      feedback: "This field is required"
    },
    highschoolLocation:{
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: 'Location'
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      feedback: "This field is required"
    },
    highschoolYear:{
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: 'Graduation Year'
      },
      value: '',
      validation: {
        required: true,
        isNumeric: true,
        minLength: 4,
        maxLength: 4,
      },
      valid: false,
      touched: false,
      feedback: "This field is required"
    },
    // univName1:{
    //   elementType: 'input',
    //   elementConfig: {
    //       type: 'text',
    //       placeholder: 'Name'
    //   },
    //   value: '',
    //   validation: {
    //     required: true
    //   },
    //   valid: false,
    //   touched: false
    // },
    // univLocation1:{
    //   elementType: 'input',
    //   elementConfig: {
    //       type: 'text',
    //       placeholder: 'Location'
    //   },
    //   value: '',
    //   validation: {
    //     required: true
    //   },
    //   valid: false,
    //   touched: false
    // },
    // univYear1:{
    //   elementType: 'input',
    //   elementConfig: {
    //       type: 'text',
    //       placeholder: 'Year'
    //   },
    //   value: '',
    //   validation: {
    //     required: true
    //   },
    //   valid: false,
    //   touched: false
    // },
    // workexpName1:{
    //   elementType: 'input',
    //   elementConfig: {
    //       type: 'text',
    //       placeholder: 'Name'
    //   },
    //   value: '',
    //   validation: {
    //     required: false
    //   },
    //   valid: false,
    //   touched: false
    // },
    // workexpPosition1:{
    //   elementType: 'input',
    //   elementConfig: {
    //       type: 'text',
    //       placeholder: 'Position'
    //   },
    //   value: '',
    //   validation: {
    //     required: false
    //   },
    //   valid: false,
    //   touched: false
    // },
    // workexpYear1:{
    //   elementType: 'input',
    //   elementConfig: {
    //       type: 'text',
    //       placeholder: 'Year'
    //   },
    //   value: '',
    //   validation: {
    //     required: false
    //   },
    //   valid: false,
    //   touched: false
    // },
    knowBy: {
      elementType: 'select',
      elementConfig: {
          options: [
              {value: '1', displayValue: 'Friends'},
              {value: '2', displayValue: 'Social Media'},
              {value: '3', displayValue: 'Websites'},
              {value: '4', displayValue: 'Other'}
          ]
      },
      value: '1',
      display: "Friends",
      validation: {
      },
      valid: true,
    },
    start: {
      elementType: 'date',
      elementConfig: {
          type: 'date',
          placeholder: ''
      },
      value: new Date(),
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    salary:{
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: 'Ex. 10000'
      },
      value: '',
      validation: {
        required: true,
        isCurrency: true
      },
      valid: false,
      touched: false,
      feedback: "This field is required"
    },
    attachment:{
      cv: {
        file: null,
        preview: null,
        validation: {
        },
        valid: false,
        touched: false,
        feedback: ""
      },
      avatar:{
        file: null,
        preview: null,
        validation: {
        },
        valid: false,
        touched: false,
        feedback: ""
      },
      other: [
        {
          file: null,
          preview: null,
          validation: {
          },
          valid: true,
          touched: false,
          feedback: ""
        }
      ]
    },

    newUniv:[
      {
        name: {
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Name '
          },
          value: '',
          validation: {
            required: true
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
        location: {
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Location'
          },
          value: '',
          validation: {
            required: true
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
        year:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Graduation Year'
          },
          value: '',
          validation: {
            required: true, 
            isNumeric: true,
            minLength: 4, 
            maxLength: 4,
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        }
      }
    ],
    newExp: [
      {
        name:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Name'
          },
          value: '',
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
        position:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Position'
          },
          value: '',
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
        year:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Joined Year'
          },
          value: '',
          validation: {
            required: true,
            isNumeric: true,
            minLength: 4,
            maxLength: 4,
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
      }
    ],
    counteruniversity: 1,
    counterworking: 1,
    provideEducation: "false",
    provideExperience: "false",
    formValid: false,
  },
}

const changeData = (state,action) =>{
  let newField = {...state.dataInterest[action.name]}
  let newFeedback = null;

  newField.value = action.value;
  newField.valid = checkValidity(action.value, state.dataInterest[action.name].validation);
  newField.feedback = updateFeedback(action.value, newField.valid, action.name);
  newField.touched = true;

  if(newFeedback!== null)
  {
    newField.feedback = newFeedback;
  }
  
  return {
    ...state,
    dataInterest:{
      ...state.dataInterest,
      [action.name]: newField
    }
  }
}

const checkFormValidation = (state, action)=>{
  let valid = false;
  let univValid = false;
  let hsValid = false;
  let expValid = false;

  for(let i = 0; i<state.dataInterest.newUniv.length; i++)
  {
    if(
      state.dataInterest.newUniv[i].name.valid && 
      state.dataInterest.newUniv[i].location.valid && 
      state.dataInterest.newUniv[i].year.valid 
      )
      {
        univValid = true;
      }
    else{
      univValid = false;
    }
  }

  for(let i = 0; i<state.dataInterest.newExp.length; i++)
  {
    if(
      state.dataInterest.newExp[i].name.valid && 
      state.dataInterest.newExp[i].position.valid && 
      state.dataInterest.newExp[i].year.valid 
      )
      {
        expValid = true;
      }
    else{
      expValid = false;
    }
  }

  if(state.dataInterest.provideEducation === "false")
  {
    if(state.dataInterest.highschoolName.valid && state.dataInterest.highschoolLocation.valid && state.dataInterest.highschoolYear.valid)
    {
      hsValid = true;
    }
  }
  if(state.dataInterest.provideEducation === "true")
  {
    hsValid= true;
  }

  if(
    state.dataInterest.email.valid && 
    state.dataInterest.phone.valid &&
    state.dataInterest.firstName.valid &&
    state.dataInterest.lastName.valid &&
    state.dataInterest.knowBy.valid &&
    state.dataInterest.start.valid &&
    state.dataInterest.salary.valid &&
    state.dataInterest.attachment.cv.valid &&
    state.dataInterest.attachment.avatar.valid && 
    hsValid && univValid && expValid
    )
  {
    valid = true;
    
  }

  return {
    ...state,
    dataInterest:{
      ...state.dataInterest,
      formValid: valid
    }
  }
}

const changeDataObject = (state,action) =>{
  let newDataInterest = {...state.dataInterest}
  // console.log(action);
  let extraField = null;

  if(action.fieldname === "newUniv"){
    extraField = "location"
  }
  if(action.fieldname === "newExp"){
    extraField = "position"
  }

  newDataInterest[action.fieldname][action.index][action.data.name].value = action.data.value
  newDataInterest[action.fieldname][action.index][action.data.name].feedback = updateFeedback(
    newDataInterest[action.fieldname][action.index][action.data.name].value, 
    newDataInterest[action.fieldname][action.index][action.data.name].valid, 
    action.data.name )

  if(newDataInterest[action.fieldname][action.index].name.value.trim() !== ''|| 
  newDataInterest[action.fieldname][action.index].year.value.trim() !== '' || 
  newDataInterest[action.fieldname][action.index][extraField].value.trim() !== '' )
  {
    if(newDataInterest[action.fieldname][action.index].name.value.trim() === ''){
      newDataInterest[action.fieldname][action.index].name.valid = false
    }
    if(newDataInterest[action.fieldname][action.index].year.value.trim() === ''){
      newDataInterest[action.fieldname][action.index].year.valid = false
    }
    if(newDataInterest[action.fieldname][action.index][extraField].value.trim() === ''){
      newDataInterest[action.fieldname][action.index][extraField].valid = false
    }
    newDataInterest[action.fieldname][action.index][action.data.name].valid = checkValidity(action.data.value, newDataInterest[action.fieldname][action.index][action.data.name].validation)  
    
    newDataInterest[action.fieldname][action.index].name.touched = true
    newDataInterest[action.fieldname][action.index].year.touched = true
    newDataInterest[action.fieldname][action.index][extraField].touched = true
  }

  if(newDataInterest[action.fieldname][action.index].name.value.trim() === '' &&
  newDataInterest[action.fieldname][action.index].year.value.trim() === '' && 
  newDataInterest[action.fieldname][action.index][extraField].value.trim() == '' )
  {
    newDataInterest[action.fieldname][action.index].name.valid = true
    newDataInterest[action.fieldname][action.index].year.valid = true
    newDataInterest[action.fieldname][action.index][extraField].valid = true

    newDataInterest[action.fieldname][action.index].name.touched = false
    newDataInterest[action.fieldname][action.index].year.touched = false
    newDataInterest[action.fieldname][action.index][extraField].touched = false
  }

  return {
    ...state,
    dataInterest: newDataInterest
  }
}

const changeStateData = (state, action)=>{
  let provide = null;
  
  let newDataInterest = {...state.dataInterest}
  switch (action.field)
  {
    case 'education' : 
      provide = "provideEducation";
      if(action.state === "true")
      {
        newDataInterest.highschoolName.validation = {}
        newDataInterest.highschoolName.valid = true
        newDataInterest.highschoolLocation.validation = {}
        newDataInterest.highschoolLocation.valid = true
        newDataInterest.highschoolYear.validation = {}
        newDataInterest.highschoolYear.validation = true

        newDataInterest.highschoolName.value = "";
        newDataInterest.highschoolName.valid = false
        newDataInterest.highschoolName.touched = false;
        newDataInterest.highschoolLocation.value = "";
        newDataInterest.highschoolLocation.valid = false
        newDataInterest.highschoolLocation.touched = false;
        newDataInterest.highschoolYear.value = "";
        newDataInterest.highschoolYear.valid = false
        newDataInterest.highschoolYear.touched = false;
        newDataInterest.newUniv = [
          {
            name: {
              elementType: 'input',
              elementConfig: {
                  type: 'text',
                  placeholder: 'Name '
              },
              value: '',
              validation: {
              },
              valid: true,
              touched: false
            },
            location: {
              elementType: 'input',
              elementConfig: {
                  type: 'text',
                  placeholder: 'Location'
              },
              value: '',
              validation: {
              },
              valid: true,
              touched: false
            },
            year:{
              elementType: 'input',
              elementConfig: {
                  type: 'text',
                  placeholder: 'Year'
              },
              value: '',
              validation: {
              },
              valid: true,
              touched: false,
              feedback: "must be number, and length must be 4 character"
            }
          }
        ]
      }

      if(action.state==="false")
      {
        newDataInterest.highschoolName.validation = { required: true }
        newDataInterest.highschoolName.valid = false
        newDataInterest.highschoolLocation.validation = { required: true }
        newDataInterest.highschoolLocation.valid = false
        newDataInterest.highschoolYear.validation = {
          required: true,
          isNumeric: true,
          minLength: 4,
          maxLength: 4,}
        newDataInterest.highschoolYear.valid = false
      }

      break;
    case 'experience' :
      provide = "provideExperience";
      if(action.state === "true")
      {
        newDataInterest.newExp = [
          {
            name:{
              elementType: 'input',
              elementConfig: {
                  type: 'text',
                  placeholder: 'Name'
              },
              value: '',
              validation: {
                required: true,
              },
              valid: true,
              touched: false,
              feedback: "required"
            },
            position:{
              elementType: 'input',
              elementConfig: {
                  type: 'text',
                  placeholder: 'Position'
              },
              value: '',
              validation: {
                required: true,
              },
              valid: true,
              touched: false,
              feedback: "required"
            },
            year:{
              elementType: 'input',
              elementConfig: {
                  type: 'text',
                  placeholder: 'Year'
              },
              value: '',
              validation: {
                required: true,
                isNumeric: true,
                minLength: 4,
                maxLength: 4,
              },
              valid: true,
              touched: false,
              feedback: "must be number, and length must be 4 character"
            },
          }
        ]
      }
      break;

    default : break;
  }
  
  newDataInterest[provide] = action.state

  return {
    ...state,
    dataInterest: newDataInterest
  }
}

const changeKnowBy = (state, action) =>{
  return {
    ...state,
    dataInterest:{
      ...state.dataInterest,
      knowBy: {
        ...state.dataInterest.knowBy,
        value: action.value,
        display: action.display
      },
    }
  }
}

const addField = (state, action) => {
  let counter = 0;
  const fieldKey = Object.keys(state.dataInterest);
  const res = action.field.substring(0, 4);
  
  for(let key of fieldKey)
  {
    if(key.substring(0, 4) === res)
    {
      counter += 1;
    }
  }

  const newIndex = (counter/3) + 1;
  let newArray = null;

  switch(action.field)
  {
    case "university" : 
      newArray = {
        ["univLocation" + newIndex] : {
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Name'
          },
          value: ''
        },
        ["univName"+ newIndex] : {
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Location'
          },
          value: ''
        },
        ["univYear" + newIndex] : {
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Year'
          },
          value: ''
        }
      }
      break;
    case 'working' :
      newArray = {
        ["workexpName" + newIndex]:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Name'
          },
          value: ''
        },
        ["workexpPosition" + newIndex]:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Position'
          },
          value: ''
        },
        ["workexpYear" + newIndex]:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Year'
          },
          value: ''
        },
      }
      break;
    default: break;
  }

  return {
    ...state,
    dataInterest: {
      ...state.dataInterest,
      ...newArray,
      ["counter" + action.field]: newIndex
    }
  }
}

const addFieldObj = (state, action) =>{
  let array = null
  let newDataInterest = {...state.dataInterest}
  
  switch(action.field)
  {
    case "newUniv": 
      array = {
        name: {
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Name '
          },
          value: '',
          validation: {
            required: true
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
        location: {
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Location'
          },
          value: '',
          validation: {
            required: true
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
        year:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Graduation Year'
          },
          value: '',
          validation: {
            required: true, 
            isNumeric: true,
            minLength: 4, 
            maxLength: 4,
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        }
      }
      break;
    case "newExp":
      array = {
        name:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Name'
          },
          value: '',
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
        position:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Position'
          },
          value: '',
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
        year:{
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Joined Year'
          },
          value: '',
          validation: {
            required: true,
            isNumeric: true,
            minLength: 4,
            maxLength: 4,
          },
          valid: true,
          touched: false,
          feedback: "This field is required"
        },
      }
      break;
    default: break;
  }

  newDataInterest[action.field].push(array);
  
   return {
    ...state,
    dataInterest: newDataInterest
  }
}

const popFieldObj = (state, action)=>{
  console.log(action.field);
  console.log(action.index);
  let newDataInterest = {...state.dataInterest}
  newDataInterest[action.field].splice(action.index, 1);
  console.log(newDataInterest);

  return {
    ...state,
    dataInterest: newDataInterest
  }
}

const addFile = (state,action)=>{
  let field = null;
  let newDataInterest= {...state.dataInterest}
  console.log(action);
  switch(action.method)
  {
    case "resume" : field = "cv"; break;
    case "avatar" : field = "avatar"; break;
    default: break;
  }

  if(action.file.size <= 2000000)
  {
    newDataInterest.attachment[field].file = action.file;
    newDataInterest.attachment[field].preview = action.preview;
    newDataInterest.attachment[field].valid = true;
    newDataInterest.attachment[field].feedback = null;
  }
  if(action.file.size > 2000000)
  {
    newDataInterest.attachment[field].valid = false;
    newDataInterest.attachment[field].feedback = "please select smaller file";
  }

  return{
    ...state,
    dataInterest: newDataInterest
  }
}

const addFileOther = (state,action)=>{
  let newDataInterest = {...state.dataInterest}

  let newItem = {
    file: null,
    preview: null,
    validation: {
    },
    valid: true,
    touched: false,
    feedback: ""
  }

  if(action.file.size <= 2000000)
  {
    newDataInterest.attachment.other[action.index].file = action.file;
    newDataInterest.attachment.other[action.index].preview = action.preview;
    newDataInterest.attachment.other[action.index].feedback = null;
    newDataInterest.attachment.other.push(newItem)
  }
  if(action.file.size > 2000000)
  {
    newDataInterest.attachment.other[action.index].feedback = "please select smaller file";
  }

  console.log(action);
  return {
    ...state,
    dataInterest: newDataInterest
  }
}

const loadDataFromCookies = (state,action) => {
  const data = JSON.parse(localStorage.getItem('dataApplicant'));
  // console.log(data);
  
  let templateAttachment = {
    cv: {
      file: null,
      preview: null,
      validation: {
      },
      valid: false,
      touched: false,
      feedback: ""
    },
    avatar:{
      file: null,
      preview: null,
      validation: {
      },
      valid: false,
      touched: false,
      feedback: ""
    },
    other: [
      {
        file: null,
        preview: null,
        validation: {
        },
        valid: true,
        touched: false,
        feedback: ""
      }
    ]
  }
  

  return{
    ...state,
    dataInterest: {
      ...data,
      attachment: {...templateAttachment},
      start: {
        ...data.start,
        value: new Date()
      },
      formValid: false
    }
  }
} 

const storeCookies = (state, action) =>{
  let newStoreWOFile = {};
  let fieldKey = Object.keys(state.dataInterest)
  let allowed = fieldKey.filter(item => {
    return item !== "attachment"
  })
  
  for(let i = 0; i<allowed.length; i++)
  {
    newStoreWOFile[allowed[i]]= state.dataInterest[allowed[i]]
  }

  localStorage.setItem("dataApplicant", JSON.stringify(newStoreWOFile));
  return state;
}

const interestReducer = (state = initialState, action) =>{
  switch(action.type)
  {
    case actionTypes.CHANGE_DATA : return changeData(state,action);
    case actionTypes.CHANGE_DATA_OBJECT : return changeDataObject(state,action);
    case actionTypes.CHECK_FORM_VALIDATION : return checkFormValidation(state,action);
    case actionTypes.TOGGLE_STATE_DATA : return changeStateData(state, action);
    case actionTypes.CHANGE_KNOWBY : return changeKnowBy(state, action);
    case actionTypes.ADD_FIELD : return addField(state, action);
    case actionTypes.ADD_FIELD_OBJECT : return addFieldObj(state, action);
    case actionTypes.POP_FIELD_OBJECT : return popFieldObj(state, action);
    case actionTypes.ADD_FILE : return addFile(state, action);
    case actionTypes.ADD_FILE_OTHER : return addFileOther(state, action);
    case actionTypes.STORE_COOKIES: return storeCookies(state,action);
    case actionTypes.LOAD_COOKIES: return loadDataFromCookies(state,action);
    default: return state;
  }
}

export default interestReducer;