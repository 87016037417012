import React from 'react'
import classes from './UploadProgress.module.scss'
import Transition from 'react-transition-group/Transition'

const UploadProgress = (props) => {
  return (
    <Transition in={props.formSubmitting} timeout={0} mountOnEnter unmountOnExit>
      {
        state=>(
          <div className={classes.modalProgress} style={{
            opacity: state === 'entering'? 0 : 1, 
            transitionDuration: '1s',
            }}>
            <div className={classes.box}>
              <p>Uploading</p>
              <div className={classes.loadingBar}>
                <span style={{width: props.progress + '%'}}></span>
              </div>
            </div>
          </div>
          )
      }
    </Transition>
    
  )
}

export default UploadProgress
