import React from 'react'
import classes from './Vacancy.module.scss'
import DetailVacancy from './DetailVacancy';

import ModalInfo from './ModalInfo'
import ReferFriend from './ReferFriend'

const VacancyList = (props) => {
  // console.log(props);
  
  return (
    <li className={classes.listItem} key={props.dataItem.id}>
      <div className={classes.listContainer}>
        <div className={classes.position}>
          <p>{props.dataItem.title}</p>
        </div>
        <div className={classes.location}>
          <p>{props.dataItem.location_name}</p>
        </div>
        <div className={classes.details}>
          <a href="/" onClick={(e)=>props.toggleShow(e, props.index , props.stateVacancy)}>Details</a>
        </div>
      </div>
      <DetailVacancy 
        index={props.index}
        interestVacancy={props.interestVacancy} 
        modalRefer={props.modalRefer}
        onInterest={props.onInterest} 
        show={props.dataItem.show} 
        dataItem={props.dataItem} 
        toggleRefer={props.toggleRefer} 
        stateVacancy={props.stateVacancy}
      />
      <ReferFriend 
        modalRefer={props.modalRefer}
        referState={props.referState}
        dataItem={props.dataItem} 
        referData={props.referData}
        index={props.index} 
        stateVacancy={props.stateVacancy}
        onRefer={props.onRefer}
        referChange={props.referChange} 
        referClear={props.referClear}
        />
      {/* <ModalInfo dataItem={props.dataItem} modalInfo={props.modalInfo} closeModalInfo={props.closeModalInfo} onNewInterest={props.onNewInterest} onEditInterest={props.onEditInterest} firstname={props.firstname} lastname={props.lastname} /> */}
    </li>
  )
}

export default VacancyList
