import React, { Component } from 'react';
import history from './store/history'
// import {BrowserRouter, Route, Switch} from 'react-router-dom'
import { Router, Route, Switch } from 'react-router-dom';
import './App.css';

// pages
import Home from './containers/Home/home'
import InterestOld from './containers/Interest/Interest_old'
import Interest from './containers/Interest/Interest'
import Test from './containers/Test/Test'
import Test2 from './containers/Test/Test2'

import notFound from './components/UI/NotFound/NotFound'

class App extends Component {
  render() {
    return (
      // <BrowserRouter basename={'/betafront'}>
      <Router history={history}>
        <div className="App">
          <Switch>
            <Route path="/" exact component={ Home } />
            <Route path="/interest_old" exact component={InterestOld} />
            <Route path="/interest" exact component={Interest} />
            <Route path="/test_old" component={ Test } />
            <Route path="/test" component={ Test2 } />
            <Route component={notFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
