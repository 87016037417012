import React from 'react'

import classes from './ModalInfo.module.scss'
import Transition from 'react-transition-group/Transition'


const ModalInfo = (props) => {
  // console.log(props);
  
  return (
    <Transition in={props.modalInfo} timeout={0} mountOnEnter unmountOnExit>
      {
        state=>(
          <div className={classes.modalContainer} style={{
            opacity: state === 'entering'? 0 : 1, 
            transitionDuration: '1s',
            }}>
            <div className={classes.modalPlc}>
              <div onClick={props.closeModalInfo} className={classes.btnClose}></div>
              {/* <p>{props.slug}</p> */}
              <p>You already submit your data as : <span>{props.firstName} {props.lastName}</span></p>
              <button onClick={(e)=>props.onNewInterest(e, props.slug)} className={classes.new}>Create New</button>
              <button onClick={(e)=>props.onEditInterest(e, props.slug)} className={classes.edit}>Edit Data</button>
            </div>
          </div>
        )
      }
    </Transition>
  )
}

export default ModalInfo
