import React, { Component } from 'react'

import {connect} from 'react-redux'
import {Route} from 'react-router-dom'

import * as actions from '../../../store/actions/index'

import Aux from '../../../hoc/Aux'
import IntroPersonality from '../../../components/Test/Personality/Intro' 
import TestPersonality from '../../../components/Test/Personality/TestPersonality'

class Personality2 extends Component {

  componentWillMount()
  {
    if(this.props.listQuestionPersonality === null)
    {
      this.props.history.replace( '/')
    }
  }

  startTimer=(time)=>{
    time = time * 1000 * 60;
    this.timer = setInterval(()=>{
      time = time - 1000
      this.props.setTimer({personality: null, english: null, counter: time})

      if(time === 0)
      {
        this.props.setTestDone('personality', true)
        this.props.setTimer({personality: null, english: null, counter: 1000 * 60 * this.props.timer.english})
        clearInterval(this.timer);
        this.props.history.replace('/test/english/intro')
      }
    }, 1000)
  }

  componentDidMount()
  {
    if(this.props.testDone.personality)
    {
      this.props.history.replace( '/test/english/intro')
    }
  }

  startTest=(event)=>{
    event.preventDefault();
    this.startTimer(this.props.timer.personality)
    this.props.history.replace( '/test/personality/1');
  }

  prevPersonality=(event)=>{
    event.preventDefault();
    const prev = parseInt(this.props.history.location.pathname.split('/').pop()) - 1
    this.props.history.replace( '/test/personality/' + prev)
  }
  nextPersonality=(event)=>{
    event.preventDefault();
    const next = parseInt(this.props.history.location.pathname.split('/').pop()) + 1
    this.props.history.replace( '/test/personality/' + next)
  }

  changeHandler = (e, id)=>{
    let answer = e.target.value;
    this.props.onAnswerPersonality({id: id, answer: answer}, "Personality");
  }

  navPersonality = (event, state)=>{
    event.preventDefault();

    let operator = 0;

    switch(state)
    {
      case ('prev'):
        operator = -1
        break;
      case('next'):
        operator = 1
        break;
      default:
        operator = 0
    }

    if(state !== 'toPG')
    {
      const path = parseInt(this.props.history.location.pathname.split('/').pop()) + parseInt(operator)
      return this.props.history.push( '/test/personality/' + path)
    }
    if(state === 'toPG')
    {
      this.props.setTestDone('personality', true)
      this.props.setTimer({personality: null, english: null, counter: 1000 * 60 * this.props.timer.english})
      clearInterval(this.timer);
      this.props.history.replace('/test/english/intro');
    }
  }

  render() {
    const path = this.props.history.location.pathname;
    const slug = path.split('/').pop();
    
    return (
      <Aux>
        <Route exact path={`${this.props.match.path}/intro`} render={()=>
          <IntroPersonality startTest={this.startTest} />
        } />
        <Route exact path={`${this.props.match.path}/:id`} render={()=>
          <TestPersonality navPersonality={this.navPersonality} path={slug} changeHandler={this.changeHandler} listPersonality={this.props.listQuestionPersonality} />
        } />
      </Aux>
    )
  }
}

const mapStateToStore = (state) =>{
  return{
    listQuestionPersonality: state.test.listQuestionPersonality,
    testDone: state.test.testDone,
    timer: state.test.timer
  }
}

const mapDispatchToStore = (dispatch) => {
  return {
    onInitPersonality: ()=> dispatch(actions.initPersonality()),
    onAnswerPersonality: (data, fieldTest)=>dispatch(actions.answerQuestion(data, fieldTest)),
    setTestDone: (fieldTest, state)=>dispatch(actions.setTestDone(fieldTest, state)),
    setTimer:(time)=>dispatch(actions.setTimer(time)),
  }
}

export default connect(mapStateToStore, mapDispatchToStore)(Personality2);