import React, { Component } from 'react'
import {Route} from 'react-router-dom'

import classes from './Test.module.scss'

import Personality from './Personality/Personality'
import English from './English/English'
import Thankyou from '../../components/Thankyou/Thankyou'

import {connect} from 'react-redux'

  class Test extends Component { 

    componentDidMount(){
    if(this.props.history.location.pathname === '/test' || this.props.history.location.pathname === '/test/' )
    {
      this.props.history.replace( '/test/personality/intro' );
    }

    if(this.props.dataApplicant === null)
    {
      this.props.history.replace('/')
    }

  }

  render() {
    return (
      <div className={classes.test}>
        <div className={classes.top}></div>
        <div className={classes.testContainer}>
          <Route path="/test/personality/" component={Personality} />
          <Route path="/test/english/" component={English} />
          <Route path="/test/thankyou" exact component={ Thankyou } />
        </div>
      </div>
    )
  }
}

const mapStateToStore = (state) =>{
  return{
    dataApplicant: state.dataUserInterest
  }
}

export default connect(mapStateToStore)(Test);