import React from 'react'

import ListEnglish from './ListEnglish'
import UploadProgressModal from '../UploadProgressModal/UploadProgress'
import Aux from '../../../hoc/Aux'

import classes from './English.module.scss'

import arrow from '../../../assets/img/arrow.png'

const TestEnglish = (props) => {
  // console.log(props);

  return (
    <Aux>
      {
        props.listEnglish ? 
        <Aux>
          {
            props.formSubmitting && props.path !== "intro" ? 
            <UploadProgressModal progress={props.progress} formSubmitting={props.formSubmitting} /> :
            <Aux>
            {
              props.formSubmitting ? null :
              props.listEnglish.map((item, index) =>(
              parseInt(props.path) === parseInt(item.id) ?
                <ListEnglish key={item.id} id={item.id} indexQuestion={index} answer={item.answer} listAnswer={item.listAnswer} question={item.question} changeHandler={props.changeHandler} /> : null
              ))
            }
            {
              props.path === "intro" ? null : 
              <div className={classes.selectionBox}>
                <a href="/" className={[parseInt(props.path) === 1 ? classes.hide : ''] + ' ' + classes.prev } 
                onClick={(e)=>props.navEnglish(e,'prev')}>BACK<span style={{backgroundImage: 'url('+ arrow +')'}}></span></a> 
                
                <a href="/" className={[parseInt(props.path) === parseInt(props.listEnglish.length) ? classes.hideNone : ''] + ' ' + classes.next } 
                onClick={(e)=>props.navEnglish(e,'next')}>NEXT<span style={{backgroundImage: 'url('+ arrow +')'}}></span></a> 

                <a href="/" className={[parseInt(props.path) === parseInt(props.listEnglish.length) ? '' : classes.hideNone] + ' ' + classes.next} 
                onClick={(e)=>props.navEnglish(e, 'thankyou')}>SUBMIT<span style={{backgroundImage: 'url('+ arrow +')'}}></span></a>
              </div>
            }
            </Aux>
          }
        </Aux>
        : null
      }  
    </Aux>
  )
}

export default TestEnglish
