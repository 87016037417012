import React from 'react'
import VacancyList from './VacancyList'

const Vacancy = (props) => {
  // console.log(props.listVacancy);
  
  // const
  let listVacancy = props.listVacancy;
  
  return (
    listVacancy.map((item, index) => {
      return(
        <VacancyList 
          key={item.id} 
          index={index}
          modalRefer={props.modalRefer}
          closeModalInfo={props.closeModalInfo}
          onNewInterest={props.onNewInterest}
          onEditInterest={props.onEditInterest}
          firstname={props.firstname}
          lastname={props.lastname}
          stateVacancy={props.stateVacancy}
          dataItem={item} 
          onRefer={props.onRefer}
          referState={props.referState}
          referData={props.referData}
          referChange={props.referChange}
          referClear={props.referClear} 
          interestVacancy={props.interestVacancy} 
          onInterest={props.onInterest} 
          toggleRefer={props.toggleRefer} 
          toggleShow={props.toggleShow} 
        />
      )
    })
  )
}

export default Vacancy