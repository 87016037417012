import * as actionTypes from './actionTypes'

export const changeData = (name, value) => {
  return {
    type: actionTypes.CHANGE_DATA,
    name: name,
    value: value
  }
}
export const changeDataObject = (fieldname,index,data) => {
  return {
    type: actionTypes.CHANGE_DATA_OBJECT,
    fieldname:fieldname,
    index: index,
    data: data
  }
}

export const checkFormValidation = ()=>{
  return{
    type:actionTypes.CHECK_FORM_VALIDATION
  }
}

export const changeStateData = (state, field) => {
  return {
    type : actionTypes.TOGGLE_STATE_DATA,
    state: state,
    field: field
  }
}
export const changeKnowBy = (value, display) => {
  return {
    type : actionTypes.CHANGE_KNOWBY,
    value: value,
    display: display
  }
}

export const addField = (field) => {
  return {
    type: actionTypes.ADD_FIELD,
    field: field
  }
}

export const addFieldObj = (field)=>{
  return {
    type: actionTypes.ADD_FIELD_OBJECT,
    field: field
  }
}

export const popFieldObj = (field, index)=>{
  return{
    type:actionTypes.POP_FIELD_OBJECT,
    field: field,
    index: index
  }
}

const singlePreview = (preview) =>{
  return new Promise((resolve, reject)=>{
    let reader = new FileReader();
    reader.readAsDataURL(preview);
    setTimeout(()=>{
      resolve(reader.result)
    }, 700)
  })
}

export const addFile = (file, method, index) => {
  const fileType = file.type.split('/').shift();
  let previewData = null;
  console.log(method);
  
  return dispatch => {
    switch(fileType)
    {
      case "image" : 
        singlePreview(file)
        .then(res=>{
          previewData = res;
          dispatch(addFileToStore(file, previewData, method, index))
        })
        break;
      case "application" :
        previewData = "pdf"
        dispatch(addFileToStore(file, previewData, method, index))
        break;
      default: break;
    }
  }
}

export const addFileToStore = (file, preview, method, index) =>{
  return {
    type: method === "attachment" ? actionTypes.ADD_FILE_OTHER : actionTypes.ADD_FILE,
    file: file,
    preview: preview,
    method: method,
    index: index
  }
}

export const loadDataFromCookies = () =>{
  return {
    type: actionTypes.LOAD_COOKIES
  }
}

export const storeCookies = (data) =>{
  return {
    type: actionTypes.STORE_COOKIES,
    data: data
  }
}