import React, { Component } from 'react'

import Aux from '../../hoc/Aux'

// store purpose
import {connect} from 'react-redux'

import * as actions from '../../store/actions/index'

// style
import classes from './Vacancy.module.scss'

import VacancyComponent from '../../components/Vacancy/Vacancy'
import ModalInfo from '../../components/Vacancy/ModalInfo'

import arrow2 from '../../assets/img/arrow-2.png'

class Vacancy extends Component {
  state = {
    keyword: "",
    modalInfo: false,
    slug: null
  }

  closeModalInfo = () =>{
    this.setState({
      ...this.state,
      modalInfo: false
    })
  }

  handleChange = (e) =>{
    const name = e.target.name;
    const value = e.target.value;

    // console.log(name);
    // console.log(value);
    
    this.setState({
      [name]: value
    })
  }

  changeLocation = (e)=>{
    const name = e.target.name;
    const value = e.target.value;

    this.props.changeSearchLocation(value)
    this.props.onSearchVacancy(this.state.keyword, value)
  }

  onSearch = (e) => {
    e.preventDefault();
    if(this.state.keyword.trim() === "" && this.props.location.id === 0)
    {
      this.props.onClearVacancy();
      return;
    }

    if(this.state.keyword.trim() === "" && this.props.location.id !== 0)
    {
      this.props.onSearchVacancy(this.state.keyword, String(this.props.location.id))
      return;
    }

    if(this.state.keyword.trim() !== "")
    {
      this.props.onSearchVacancy(this.state.keyword, String(this.props.location.id))
    }
  }

  referChange = (e)=> {
    const name = e.target.name;
    const value = e.target.value;
    this.props.onReferChange({name: name, value: value}); 

  }

  referClear = (index,stateVacancy) =>{
    this.props.onReferClear();
    this.toggleRefer(index, stateVacancy);
  }

  onRefer = (e, slug)=>{
    e.preventDefault();

    this.props.onReferChange({name: "vacancy", value: slug}); 
    this.modalRefer(false);
    setTimeout(() => {
      console.log(this.props.refer);
      this.props.onReferVacancy(this.props.refer)
    }, 100);
  }

  toggleShow = (e, index, stateVacancy) => {
    e.preventDefault();
    this.props.toggleShow(index, stateVacancy)
  }

  toggleRefer = (index, stateVacancy) => {
    // console.log(index, stateVacancy)
    this.props.toggleRefer(index, stateVacancy)
  }

  modalRefer = (state) => {
    // e.preventDefault();
    this.props.modalRefer(state);
  }

  interestVacancy = (e,item, state)=>{
    e.preventDefault();

    let data = JSON.parse(localStorage.getItem('dataApplicant'));
    if(data && data.formValid)
    {
      this.setState({
        ...this.state,
        modalInfo: state,
        slug: item.slug
      })
    }
    else{
      this.onNewInterest(e, item.slug);
    }
  }

  onNewInterest=(e, item)=>{
    this.props.onInterestVacancy(item);
    this.props.onInterest(e);
  }

  onEditInterest=(e, item)=>{
    this.props.loadDataApplicant();
    this.props.onInterestVacancy(item);
    this.props.onInterest(e);
  }

  moreVacancy = (e) =>{
    e.preventDefault();
    // console.log(this.props);
    
    this.props.onLoadMoreVacancy(this.props.listVacancy.length);
  }

  componentDidMount()
  {
    // console.log(this.props);
    
  }

  render() {
    let listFilteredVacancy = this.props.listFilteredVacancy
    let listVacancy = this.props.listVacancy
    let listLocation = this.props.listLocation
    
    let data = JSON.parse(localStorage.getItem('dataApplicant'));
    let firstname = "";
    let lastname = "";

    if(data)
    {
      firstname = data.firstName.value;
      lastname = data.lastName.value;
    }else
    {
      firstname = "";
      lastname="";
    }

    return (
      <Aux>
        <div className={classes.vacancyTitle}>
          <h2>Our Vacancy</h2>
          <p>Acknowledge Your Strongest Forte To Grow Together With Varnion</p>
        </div>

        {
          listVacancy.length > 0 ?
          <Aux>
            <div className={classes.vacancySearch}>
              <form onSubmit={(e)=>this.onSearch(e)}>
                <input className={classes.search} onChange={this.handleChange} name="keyword" type="text" placeholder="Search Jobs" />
                <div className={classes.locationContainer}>
                  <select name="location" onChange={this.changeLocation} ref={this.searchLocationRef}>
                    <option selected={this.props.location.id === 0? "true": null} value={0}>All Location</option>    
                    {
                      listLocation = listLocation.map(item=>{
                        return <option key={item.id} selected={this.props.location.id === item.id? "true": null} value={item.id}>{item.location}</option>
                      })
                    }
                  </select>
                  <div className={classes.arrow}>
                    <span className={classes.arrowContainer} style={{backgroundImage: 'url('+ arrow2 +')'}} ></span>
                  </div>
                </div>
                <button>Search</button>
              </form>
            </div>
            
            {
              listFilteredVacancy.length > 0 || this.props.location.id !== 0 ?
              <Aux>
                {
                  this.props.searchVacancy !== null ?
                  <Aux>
                    {
                      this.props.location.id === 0 ? 
                      <Aux>
                      <p className={classes.keyword}>there {listFilteredVacancy.length === 1 ? "is" : "are"} <span>{listFilteredVacancy.length}</span> {listFilteredVacancy.length === 1 ? "vacancy" : "vacancies"} for <span>{this.props.searchVacancy}</span></p> 
                      <ul className={classes.vacancyList + ' ' + classes.title}>
                        <li className={classes.listItem}>
                          <div className={classes.listContainer}>
                            <div className={classes.position}>
                              <p>Position</p>
                            </div>
                            <div className={classes.location}>
                              <p>Location</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      </Aux>
                      :
                      <Aux>
                        {
                          listFilteredVacancy.length > 0 ?
                          <Aux>
                            <p className={classes.keyword}>there {listFilteredVacancy.length === 1 ? "is" : "are"} <span>{listFilteredVacancy.length}</span> {listFilteredVacancy.length === 1 ? "vacancy" : "vacancies"} for <span>{this.props.searchVacancy}</span> in <span>{this.props.location.display}</span></p> 
                            <ul className={classes.vacancyList + ' ' + classes.title}>
                              <li className={classes.listItem}>
                                <div className={classes.listContainer}>
                                  <div className={classes.position}>
                                    <p>Position</p>
                                  </div>
                                  <div className={classes.location}>
                                    <p>Location</p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Aux>
                          :
                          <p className={classes.keyword}>there is no vacancy for <span>{this.props.searchVacancy}</span> in <span>{this.props.location.display}</span></p> 
                        }
                      </Aux>
                    }
                  </Aux>
                  :
                  <Aux>
                    {
                      listFilteredVacancy.length> 0 ?
                      <Aux>
                      <p className={classes.keyword}>there {listFilteredVacancy.length === 1 ? "is" : "are"} <span>{listFilteredVacancy.length}</span> {listFilteredVacancy.length === 1 ? "vacancy" : "vacancies"} in <span>{this.props.location.display}</span></p> 
                      <ul className={classes.vacancyList + ' ' + classes.title}>
                        <li className={classes.listItem}>
                          <div className={classes.listContainer}>
                            <div className={classes.position}>
                              <p>Position</p>
                            </div>
                            <div className={classes.location}>
                              <p>Location</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      </Aux>
                      :
                      <p className={classes.keyword}>there is no vacancy in <span>{this.props.location.display}</span></p> 
                    }
                  </Aux>
                }
              </Aux>
              : null
            }

            {
              this.props.location.id === 0 && this.props.searchVacancy === null ?
              <ul className={classes.vacancyList + ' ' + classes.title}>
                <li className={classes.listItem}>
                  <div className={classes.listContainer}>
                    <div className={classes.position}>
                      <p>Position</p>
                    </div>
                    <div className={classes.location}>
                      <p>Location</p>
                    </div>
                  </div>
                </li>
              </ul>
              : null
            }
            

            {
              listFilteredVacancy.length < 1 && this.props.location.id === 0 && this.props.searchVacancy !== null ? 
              <p className={classes.keyword}>there is no vacancy for <span>{this.props.searchVacancy}</span> </p> 
              : 
              null
            }

            <ul className={classes.vacancyList}>
              {
                this.props.location.id === 0 && this.props.searchVacancy === null ?
                <VacancyComponent 
                  onRefer={this.onRefer}
                  modalInfo={this.state.modalInfo}
                  modalRefer={this.modalRefer}
                  closeModalInfo={this.closeModalInfo}
                  onNewInterest={this.onNewInterest}
                  onEditInterest={this.onEditInterest}
                  firstname={firstname}
                  lastname={lastname}
                  referData={this.props.refer}
                  referState={this.props.refer.referState}
                  referClear ={this.referClear}
                  referChange={this.referChange} 
                  interestVacancy={this.interestVacancy} 
                  listVacancy={listVacancy} 
                  stateVacancy="initial" 
                  onInterest={this.props.onInterest} 
                  toggleRefer={this.toggleRefer} 
                  toggleShow={this.toggleShow} 
                />
                :
                <Aux>
                  {
                    listFilteredVacancy.length > 0 ?
                    <VacancyComponent 
                      onRefer={this.onRefer}
                      modalInfo={this.state.modalInfo}
                      modalRefer={this.modalRefer}
                      closeModalInfo={this.closeModalInfo}
                      onNewInterest={this.onNewInterest}
                      onEditInterest={this.onEditInterest}
                      firstname={firstname}
                      lastname={lastname}
                      referData={this.props.refer}
                      referState={this.props.refer.referState}
                      referClear={this.referClear}
                      referChange={this.referChange} 
                      interestVacancy={this.interestVacancy} 
                      listVacancy={listFilteredVacancy} 
                      stateVacancy="searching" 
                      onInterest={this.props.onInterest} 
                      toggleRefer={this.toggleRefer} 
                      toggleShow={this.toggleShow} 
                    />
                    :
                    null
                  }
                </Aux>
              }
            </ul>
            
            {
              this.props.moreVacancy && this.props.location.id === 0 && this.searchVacancy === null ? 
              <button onClick={this.moreVacancy} className={classes.moreVacancy}>Load More</button> 
              : null
            }
          </Aux> 
          :
          <p className={classes.info}>There is no open vacancy right now.</p>
        }

        {
          this.props.moreVacancy && this.props.searchVacancy === null && listVacancy && this.props.location.id === 0 ? 
          <button onClick={this.moreVacancy} className={classes.moreVacancy}>Load More</button> 
          : 
          null
        }

        <ModalInfo modalInfo={this.state.modalInfo} slug={this.state.slug} firstName={firstname} lastName={lastname} 
          closeModalInfo={this.closeModalInfo} onNewInterest={this.onNewInterest} onEditInterest={this.onEditInterest}
        />
      </Aux>
    )
  }
}

const mapStateToStore = (state) => {
  return{
    listVacancy: state.vacancy.listVacancy,
    listFilteredVacancy: state.vacancy.listFilteredVacancy,
    listLocation: state.vacancy.listLocation,
    location: state.vacancy.searchLocation,
    searchVacancy: state.vacancy.searchKeywordFilter,
    moreVacancy: state.vacancy.moreVacancy,
    refer: state.vacancy.refer,

    dataApplicant: state.interest.dataInterest,
    firstname: state.interest.dataInterest.firstName.value,
    lastname: state.interest.dataInterest.lastName.value,
  }
}

const mapDispatchToStore = (dispatch) => {
  return {
    loadDataApplicant : ()=>dispatch(actions.loadDataFromCookies()),
    toggleShow: (index, stateVacancy) => { 
      dispatch(actions.toggleShow(index, stateVacancy))
    },
    toggleRefer: (index, stateVacancy) => {
      dispatch(actions.toggleRefer(index, stateVacancy))
    },
    onInitVacancy: ()=>{
      dispatch(actions.initVacancy())
    },
    changeSearchLocation:(keyword)=>dispatch(actions.changeSearchLocation(keyword)),
    onSearchVacancy: (keyword, location)=> dispatch(actions.searchVacancy(keyword, location)),
    onClearVacancy: ()=> dispatch(actions.clearFilteredVacancy()),
    onInterestVacancy: (item)=> dispatch(actions.fillInterest(item)),
    onLoadMoreVacancy: (length)=> dispatch(actions.loadVacancy(length)),

    modalRefer: (state)=> dispatch(actions.modalRefer(state)),
    onReferChange: (dataItem)=>{dispatch(actions.referFriendChange(dataItem))},
    onReferClear: ()=>{dispatch(actions.referFriendClear())},
    onReferVacancy: (dataItem)=> {
      dispatch(actions.referFriend(dataItem)
      )
    }
  }
}

export default connect(mapStateToStore, mapDispatchToStore)(Vacancy);
