import React from 'react'
import classes from './FilePreview.module.scss'

import pdfIcon from '../../../assets/img/pdf.png'

import Aux from '../../../hoc/Aux'

const FilePreview = (props) => {
  return (
    <div className={classes.previewContainer}>
      {
        props.cv.preview !== null ?
          <div className={classes.attachmentItemContainer}>
            <div className={classes.attachmentItem}>
              {
                props.cv.preview !== "pdf" ?
                <img src={props.cv.preview} alt="resume"></img> 
                :
                <img className={classes.pdfAttachment} src={pdfIcon} alt="resume"></img>
              }
              <div className={classes.infoAttachment}>
                <p>Resume</p>
              </div>
            </div>
          </div> :null
      }
      {
        props.avatar.preview !== null ?
        <div className={classes.attachmentItemContainer}>
          <div className={classes.attachmentItem}>
            <img src={props.avatar.preview} alt="avatar"></img>
            <div className={classes.infoAttachment}>
              <p>Avatar</p>
            </div>
          </div>
        </div>
        : null
      }
      {
        props.otherAttachment[0].preview !== null ? 
        <Aux>
          {
            props.otherAttachment.map((item, index)=>{
              return (
                <Aux>
                  {
                    item.preview !== null ?
                    <div className={classes.attachmentItemContainer}>
                      <div className={classes.attachmentItem}>
                        {
                          item.preview !== "pdf" ?
                          <img src={item.preview} alt={"other" + index}></img>
                          :
                          <img className={classes.pdfAttachment} src={pdfIcon} alt={"other" + index}></img>
                        }
                        <div className={classes.infoAttachment}>
                          <p>Other ({index+1})</p>
                        </div>
                      </div>
                    </div> : null
                  }
                </Aux>
              )
            })
          }
        </Aux> : null
      }
    </div>
  )
}

export default FilePreview
