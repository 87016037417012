import * as actionTypes from '../actions/actionTypes'

import {updateObject, checkValidity} from '../utility'
const initialState = {
  modalRefer: false,
  referring: null,
  refer:{
    vacancy: "",
    name: {
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: '* Name'
      },
      value: '',
      validation: {
          required: true,
      },
      valid: false,
      touched: false,
      feedback: "required"
    },
    email: {
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: '* Email'
      },
      value: '',
      validation: {
          required: true,
          isEmail: true
      },
      valid: false,
      touched: false,
      feedback: "email is not valid"
    },
    phone: {
      elementType: 'input',
      elementConfig: {
          type: 'text',
          placeholder: '* Phone'
      },
      value: '',
      validation: {
          isNumeric: true
      },
      valid: false,
      touched: false,
      feedback: "phone must be number"
    },
    referState: false
  },
  listLocation:[
    {id: 1, location: "Jakarta"}, 
    {id: 2, location: "Bali"}, 
    {id: 3, location: "Bandung"},
    {id: 4, location: "Jogja"}
  ],
  listVacancy: [],
  listFilteredVacancy: [],
  searchKeywordFilter: null,
  searchLocation: {id: 0, display: "All Location"},
  moreVacancy: true
}

const setVacancy = (state, action)=>{
  let newVacancy = [...state.listVacancy]
  switch(action.methodSet)
  {
    case 'init':
      newVacancy = action.listVacancy;
      break;
    case 'addMore' :
      newVacancy.push(...action.listVacancy)
      break;
    default: break;
  }

  return updateObject(state, {listVacancy: newVacancy, moreVacancy: action.stateMore})
}

const changeSearchLocation=(state, action)=>{
  return state;
}

const setSearchVacancy = (state,action) =>{
  let location = ""
  switch(action.searchLocation)
  {
    case '0' : location = "All Location"; break;
    case '1' : location = "Jakarta"; break;
    case '2' : location = "Bali"; break;
    case '3' : location = "Bandung"; break;
    case '4' : location = "Jogja"; break;
    default: break;
  }
  return updateObject(state, {
    searchKeywordFilter: action.searchKeyword, 
    searchLocation: {id: parseInt(action.searchLocation), display: location}, 
    listFilteredVacancy: action.filteredVacancy})
}

const clearSearchVacancy = (state, action)=>{
  return updateObject(state, {searchKeywordFilter: null, listFilteredVacancy: []})
}

const toggleShow = (state,action)=>{
  let fieldName = null
  let newListVacancy = []

  let otherListName = null
  let otherListVacancy = []

  if(action.stateVacancy === "initial")
  {
    fieldName = "listVacancy"
    otherListName = "listFilteredVacancy"
  }
  else{
    fieldName = "listFilteredVacancy"
    otherListName = "listVacancy"
  }

  newListVacancy = [...state[fieldName]]
  otherListVacancy = [...state[otherListName]]

  newListVacancy[action.index].show = !newListVacancy[action.index].show
  if(newListVacancy[action.index].show === false)
  {
    newListVacancy[action.index].refer = false
  }

  if(otherListVacancy.length>0)
  {
    for(let i = 0; i < otherListVacancy.length; i++)
    {
      otherListVacancy[i].show = false
      otherListVacancy[i].refer = false
    }
  }

  return updateObject(state, {[fieldName]: newListVacancy , [otherListName]: otherListVacancy})
}

const toggleRefer = (state,action)=>{
  let newListVacancy = []
  let fieldName = ""
  
  if(action.index !== undefined && action.stateVacancy !== undefined)
  {
    if(action.stateVacancy === 'initial')
    {
      newListVacancy = [...state.listVacancy]
      fieldName = "listVacancy"
    }
    else{
      newListVacancy = [...state.listFilteredVacancy]
      fieldName = "listFilteredVacancy"
    }
    newListVacancy[action.index].refer = !newListVacancy[action.index].refer
    return updateObject(state, {[fieldName]: newListVacancy, referring: { index: action.index, state: action.stateVacancy }})
  }
  else
  {
    if(state.referring.state === 'initial')
    {
      newListVacancy = [...state.listVacancy]
      fieldName = "listVacancy"
    }
    else
    {
      newListVacancy= [...state.listFilteredVacancy]
      fieldName = "listFilteredVacancy"
    }
    
    newListVacancy[state.referring.index].refer = !newListVacancy[state.referring.index].refer
    return updateObject(state,{[fieldName]: newListVacancy, referring: { index: state.referring.index, state: state.referring.state }})
  }
}

const modalRefer = (state,action)=>{
  return{
    ...state,
    modalRefer : action.state
  }
}

const referChange = (state,action) =>{
  let newRefer = {...state.refer}
  if(action.dataItem.name !== "vacancy")
  {
    newRefer[action.dataItem.name].value = action.dataItem.value
    newRefer[action.dataItem.name].touched = true
    newRefer[action.dataItem.name].valid = checkValidity(action.dataItem.value, state.refer[action.dataItem.name].validation)
  }
  if(action.dataItem.name === "vacancy")
  {
    newRefer.vacancy = action.dataItem.value
  }
  

  if(state.refer.name.valid && state.refer.email.valid && state.refer.phone.valid)
  {
    newRefer.referState = true;
  }else
  {
    newRefer.referState = false
  }
  
  return{
    ...state,
    refer : newRefer
  }
}

const referClear = (state,action) =>{
  return updateObject(state, {
    refer:{
      vacancy: "",
      name: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '* Name'
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        touched: false,
        feedback: "required"
      },
      email: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '* Email'
        },
        value: '',
        validation: {
            required: true,
            isEmail: true
        },
        valid: false,
        touched: false,
        feedback: "email is not valid"
      },
      phone: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '* Phone'
        },
        value: '',
        validation: {
            isNumeric: true
        },
        valid: false,
        touched: false,
        feedback: "phone must be number"
      }
    }
  })
}

const vacancyReducer = (state = initialState, action) =>{
  switch(action.type)
  {
    case actionTypes.SET_VACANCY: return setVacancy(state,action);
    case actionTypes.CHANGE_SEARCH_LOCATION: return changeSearchLocation(state,action);
    case actionTypes.SET_SEARCH_VACANCY: return setSearchVacancy(state,action);
    case actionTypes.CLEAR_FILTERED_VACANCY: return clearSearchVacancy(state,action);

    case actionTypes.TOGGLE_SHOW: return toggleShow(state,action);
    case actionTypes.TOGGLE_REFER: return toggleRefer(state,action);

    case actionTypes.REFER_FRIEND_CHANGE: return referChange(state,action);
    case actionTypes.REFER_FRIEND_CLEAR: return referClear(state,action);
    case actionTypes.MODAL_REFER: return modalRefer(state,action);
    default: return state;

  }
} 

export default vacancyReducer;