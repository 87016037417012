import React from 'react'

import classes from './FormInput.module.scss'

// component
import Aux from '../../../hoc/Aux'
import Input from '../../UI/Input/Input'
import moneyFormatter from 'money-formatter'
// img
import minus from '../../../assets/img/icon-minus.png'
import plus from '../../../assets/img/icon-plus.png'
import arrow2 from '../../../assets/img/arrow-2.png'
const FormInput = (props) => {
  // console.log(props);
  
  return (
    <form className={classes.form} onSubmit={(e)=>props.submit(e)}>
      <div className={classes.section}>
        <h4>Name</h4>
        <div className={classes.row}>
          <Input field="firstName" data={props.dataApplicant} change={props.change} 
            touched={props.dataApplicant.firstName.touched}
            invalid={!props.dataApplicant.firstName.valid} 
            feedback={props.dataApplicant.firstName.feedback}
            />
          <Input field="lastName" data={props.dataApplicant} touched={false} change={props.change}
            touched={props.dataApplicant.lastName.touched}
            invalid={!props.dataApplicant.lastName.valid}
            feedback={props.dataApplicant.lastName.feedback}
            />
        </div>
      </div>
      <div className={classes.email + ' ' + classes.section}>
        <h4>Email</h4>
        <div className={classes.row}>
          <Input field="email" data={props.dataApplicant} change={props.change} 
            touched={props.dataApplicant.email.touched}
            invalid={!props.dataApplicant.email.valid} 
            feedback={props.dataApplicant.email.feedback}
          />
        </div>
      </div>
      <div className={classes.section + ' ' + classes.phone}>
        <h4>Phone</h4>
        <div className={classes.row}>
          <Input field="phone" data={props.dataApplicant} change={props.change}
          touched={props.dataApplicant.phone.touched}
          invalid={!props.dataApplicant.phone.valid} 
          feedback={props.dataApplicant.phone.feedback}
          />
        </div>
      </div>
      <div className={classes.section}>
        <h4>Personal Information</h4>
        <p className={classes.provide}>DO YOU PROVIDE LAST EDUCATION INFORMATION IN YOUR RESUME?</p>
        <ul className={classes.selectionRadio}>
          <Input field="switchState" checked={props.dataApplicant.provideEducation === "true" ? "true" : "false"} id="t-edu-option" name="education" value="Yes" changeStateData={props.changeStateData} state="true" />
          <Input field="switchState" checked={props.dataApplicant.provideEducation === "false" ? "true" : "false"} id="f-edu-option" name="education" value="No" changeStateData={props.changeStateData} state="false" />
        </ul>
      </div>
      <div className={classes.section + ' ' + classes.highSchool + ' ' + [props.dataApplicant.provideEducation === "true" ? null : classes.show]} >
        <h4 className={classes.semiBold}>**High School</h4>
        <div className={classes.row}>
          <Input field="highschoolName" data={props.dataApplicant} change={props.change}
          touched={props.dataApplicant.highschoolName.touched}
          invalid={!props.dataApplicant.highschoolName.valid} 
          feedback={props.dataApplicant.highschoolName.feedback}
          />
          <Input field="highschoolLocation" data={props.dataApplicant} change={props.change}
          touched={props.dataApplicant.highschoolLocation.touched}
          invalid={!props.dataApplicant.highschoolLocation.valid} 
          feedback={props.dataApplicant.highschoolLocation.feedback}
          />
          <Input field="highschoolYear" data={props.dataApplicant} change={props.change}
          touched={props.dataApplicant.highschoolYear.touched}
          invalid={!props.dataApplicant.highschoolYear.valid} 
          feedback={props.dataApplicant.highschoolYear.feedback}
          />
        </div>
      </div>
      <div className={classes.section + ' ' + classes.university + ' ' + [props.dataApplicant.provideEducation === "true" ? "" : classes.show]}>
        <h4 className={classes.semiBold}>**University</h4>
        {
          Object.keys(props.dataApplicant.newUniv).map(index=>{
            return(
              <div key={"univ" + index} className={classes.row}>
                <Input field="fieldObject" fieldName="newUniv" index={index} 
                data={props.dataApplicant.newUniv[index].name} name="name" changeNew={props.changeNew} 
                touched={props.dataApplicant.newUniv[index].name.touched}
                invalid={!props.dataApplicant.newUniv[index].name.valid} 
                feedback={props.dataApplicant.newUniv[index].name.feedback}
                />
                <Input field="fieldObject" fieldName="newUniv" index={index} 
                data={props.dataApplicant.newUniv[index].location} name="location" changeNew={props.changeNew} 
                touched={props.dataApplicant.newUniv[index].location.touched}
                invalid={!props.dataApplicant.newUniv[index].location.valid} 
                feedback={props.dataApplicant.newUniv[index].location.feedback}
                />
                <Input field="fieldObject" fieldName="newUniv" index={index} 
                data={props.dataApplicant.newUniv[index].year} name="year" changeNew={props.changeNew} 
                touched={props.dataApplicant.newUniv[index].year.touched}
                invalid={!props.dataApplicant.newUniv[index].year.valid} 
                feedback={props.dataApplicant.newUniv[index].year.feedback}
                />
                {
                  props.dataApplicant.newUniv.length < 2 && index == 0 ?
                  <span className={classes.btnContainer}>
                    <button onClick={(e)=>props.addFieldObj(e, 'newUniv')} style={{backgroundImage: 'url('+ plus +')'}}></button>
                  </span> :
                  <span className={classes.btnContainer}>
                    <button onClick={(e)=>props.popFieldObj(e, 'newUniv')} style={{backgroundImage: 'url('+ minus +')'}}></button>
                  </span>
                }
              </div>
            )
          })
        }
      </div>
      <div className={classes.section}>
        <p className={classes.provide}>DO YOU PROVIDE WORK EXPERIENCE INFORMATION IN YOUR RESUME?</p>
        <ul className={classes.selectionRadio}>
          <Input field="switchState" checked={props.dataApplicant.provideExperience === "true" ? "true" : "false"} id="t-exp-option" name="experience" value="Yes" changeStateData={props.changeStateData} state="true" />
          <Input field="switchState" checked={props.dataApplicant.provideExperience === "false" ? "true" : "false"} id="f-exp-option" name="experience" value="No" changeStateData={props.changeStateData} state="false" />
        </ul>
      </div>
      <div className={classes.section + ' ' + classes.experience + ' ' + [props.dataApplicant.provideExperience === "true" ? "" : classes.show]}>
        <h4 className={classes.semiBold}>**Work Experience</h4>
        {
          Object.keys(props.dataApplicant.newExp).map(index=>{
            return(
              <div key={"exp" + index} className={classes.row}>
                <Input field="fieldObject" fieldName="newExp" index={index} 
                data={props.dataApplicant.newExp[index].name} name="name" changeNew={props.changeNew} 
                touched={props.dataApplicant.newExp[index].name.touched}
                invalid={!props.dataApplicant.newExp[index].name.valid} 
                feedback={props.dataApplicant.newExp[index].name.feedback}
                />
                <Input field="fieldObject" fieldName="newExp" index={index} 
                data={props.dataApplicant.newExp[index].position} name="position" changeNew={props.changeNew} 
                touched={props.dataApplicant.newExp[index].position.touched}
                invalid={!props.dataApplicant.newExp[index].position.valid} 
                feedback={props.dataApplicant.newExp[index].position.feedback}
                />
                <Input field="fieldObject" fieldName="newExp" index={index} 
                data={props.dataApplicant.newExp[index].year} name="year" changeNew={props.changeNew} 
                touched={props.dataApplicant.newExp[index].year.touched}
                invalid={!props.dataApplicant.newExp[index].year.valid} 
                feedback={props.dataApplicant.newExp[index].year.feedback}
                />
                {
                  index != [props.dataApplicant.newExp.length - 1] ?
                  <span className={classes.btnContainer}>
                    <button onClick={(e)=>props.popFieldObj(e, 'newExp', index)} style={{backgroundImage: 'url('+ minus +')'}}></button>
                  </span>: 
                  <span className={classes.btnContainer}>
                    <button onClick={(e)=>props.addFieldObj(e, 'newExp')} style={{backgroundImage: 'url('+ plus +')'}}></button>
                  </span>
                }
              </div>
            )
          })
        }
      </div>
      
      <div className={classes.section + ' ' + classes.howKnow} onMouseLeave={props.closeModalSelection}>
        <h4>How Did You Know About Us ?</h4>
        <div className={classes.row}>
          <p onClick={()=>props.switchStateModalSelection()}>{props.dataApplicant.knowBy.display}<span className={classes.arrow} style={{backgroundImage: 'url('+ arrow2 +')'}}></span></p>
          <Input 
            field="selection" 
            switchStateModalSelection={props.switchStateModalSelection}
            selectionData={props.dataApplicant.knowBy.elementConfig.options} 
            modalSelection={props.modalSelection}
            changeSelectionKnow={props.changeSelectionKnow} />
        </div>
      </div>

      <div className={classes.section + ' ' + classes.start}>
        <h4>When Could You Start</h4>
        <div className={classes.datePicker + ' ' + classes.row}>
          <Input field="start" data={props.dataApplicant} changeDate={props.changeDate} />
        </div>
      </div>

      <div className={classes.section + ' ' + classes.salary}>
        <h4>Expected Salary</h4>
        <div className={classes.row}>
          <Input field="salary" data={props.dataApplicant} change={props.change}
            touched={props.dataApplicant.salary.touched}
            invalid={!props.dataApplicant.salary.valid} 
            feedback={props.dataApplicant.salary.feedback}
          />
        </div>
      </div>

      {
        props.dataApplicant.formValid? 
        <div className={classes.section + ' ' + classes.btnContainer + ' ' + classes.row}>
          <button type="submit" >Submit</button>
        </div>
        :
        <div className={classes.section + ' ' + classes.buttonInfo + ' ' + classes.btnContainer + ' ' + classes.disabled + ' ' + classes.row}>
          <p className={classes.info}>make sure all required fields are complete</p>
          <button type="button" >Submit</button>
        </div>
      }
    </form>
  )
}

export default FormInput
