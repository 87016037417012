import * as actionTypes from '../actions/actionTypes'

import {updateObject} from '../utility'

const initialState = {
  listQuestionPersonality: null,
  listQuestionEnglish: null,
  progressUpload: 0,
  submitting: false,
  timer: {
    english: 0,
    personality: 10,
    counter: 0,
  },
  testDone: {
    personality: false,
    english: false
  }
}

const setTimer=(state,action)=>{
  if(action.timer.personality === null && action.timer.english === null)
  {
    return{
      ...state,
      timer:{
        ...state.timer,
        counter: action.timer.counter
      }
    }
  }
  if(action.timer.personality !== null && action.timer.english !== null)
  {
    return{
      ...state,
      timer: {
        ...state.timer,
        personality: parseInt(action.timer.personality),
        // personality: 0.2,
        english: parseInt(action.timer.english),
        // english: 0.2,
        counter: parseInt(action.timer.counter)
      }
    }
  }
  return state;
}

const setQuestionPersonality = (state,action) =>{
  return updateObject(state, {listQuestionPersonality: action.listPersonality})
}

const setQuestionEnglish = (state,action) =>{
  return updateObject(state, {listQuestionEnglish: action.listEnglish})
}

const fillQuestion = (state, action) =>{
  switch(action.fieldTest)
  {
    case "Personality" :
      const index = action.data.id - 1
      const answer = action.data.answer
      let fieldName = state["listQuestion" + action.fieldTest]
    
      fieldName[index] = {...fieldName[index], answer: answer}
    
      return {
        ...state,
        ["listQuestion" + action.fieldTest] : [...fieldName]
      }
    case "English" :
      let newTestEnglish = [...state.listQuestionEnglish]
      newTestEnglish[action.data.index].answer = action.data.id
      return {
        ...state,
        listQuestionEnglish: newTestEnglish
      }
    default: break;
  }

  return state;
}

const progressUploadFormData = (state, action) =>{
  return updateObject(state, {progressUpload: action.progress, submitting: action.submitting})
}

const setTestDone = (state,action)=>{
  return{
    ...state,
    testDone: {
      ...state.testDone,
      [action.fieldTest]: action.state
    }
  }
}

const clearLocalStorage = (state,action) =>{
  localStorage.removeItem('dataApplicant');
  return state;
}

const testReducer = (state = initialState, action) =>{
  switch(action.type)
  {
    case actionTypes.SET_TIMER_TEST : return setTimer(state,action)
    case actionTypes.SET_PERSONALITY : return setQuestionPersonality(state,action)
    case actionTypes.SET_ENGLISH : return setQuestionEnglish(state,action)
    case actionTypes.ANSWER_QUESTION : return fillQuestion(state,action)
    case actionTypes.SET_TEST_DONE : return setTestDone(state, action)
    case actionTypes.PROGRESS_UPLOAD_FORMDATA : return progressUploadFormData(state, action)
    case actionTypes.CLEAR_LOCAL_STORAGE : return clearLocalStorage(state, action)

    default: return state;
  }
} 

export default testReducer;