import React from 'react'
import classes from './VCCList.module.scss'

const VCCList = (props) => {
  
  return (
    <div onClick={props.click} className={ classes[props.classify] + ' ' + classes.vccItem} style={{backgroundImage: 'url(' + props.uploadUrl + props.img +')'}}>
      <div className={classes.vccWrapper}>
        <div className={classes.vccContent} dangerouslySetInnerHTML={{__html: props.content}} />
      </div>
    </div>
  )
}

export default VCCList
