import React from 'react'
import classes from './Personality.module.scss'

const ListPersonality = (props) => {
  // console.log(props);
  
  return (
    <div className={classes.testContainer}>
      <p className={classes.number}>{props.id}.</p>
      <span dangerouslySetInnerHTML={{
       __html: props.question
     }}/>
      <textarea value={props.answer} onChange={(e)=>props.changeHandler(e, props.id)}></textarea>
    </div>
  )
}

export default ListPersonality
