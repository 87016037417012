import React from 'react'
import ms from 'pretty-ms'
import classes from './Timer.module.scss'

const Timer = (props) => {
  return (
    <div className={classes.timeContainer}>
      <p>timer : {ms(props.timer)}</p>
    </div>
  )
}

export default Timer
