import React from 'react'

import classes from './Personality.module.scss'
import Aux from '../../../hoc/Aux'

import arrow from '../../../assets/img/arrow.png'
import iconLeft from '../../../assets/img/icon-quiz-1.png'
import iconRight from '../../../assets/img/icon-quiz-2.png'

const Intro = (props) => {
  return (
    <Aux>
      <div className={classes.introPersonality}>
        <h4>Whoa ! <span className={classes.lineBreak}></span>
            We‘re delighted to have your submission submitted. <span className={classes.lineBreak}></span>
            We have another thing for you to fill in.
        </h4>
        <p>Clue : we want to see if your personality could rock with us !</p>
        <h2>ARE YOU READY ?</h2>
        <div className={classes.imgLeft + ' ' + classes.icon}>
          <img src={iconLeft} />
        </div>
        <div className={classes.imgRight + ' ' + classes.icon}>
          <img src={iconRight}/>
        </div>
      </div>

      <div className={classes.selectionBox + ' ' + classes.end + ' ' + classes.intro}>
        <a href="/" className={classes.next} style={{backgroundImage: 'url('+ arrow +')'}}
        onClick={props.startTest}>YES I'M READY</a>
      </div>
    </Aux>
  )
}

export default Intro
