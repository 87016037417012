export const SET_LOADER = 'SET_LOADER'
export const SET_STATE_LOADER = 'SET_STATE_LOADER'

export const SCROLL_VACANCY = 'SCROLL_VACANCY';
export const TOGGLE_REFER = 'TOGGLE_REFER';
export const FILTERED_VACANCY = 'FILTERED_VACANCY';

export const INIT_HOME_CONTENT = 'INIT_HOME_CONTENT'
export const SET_HOME_CONTENT = 'SET_HOME_CONTENT'

// vacancy
export const CHANGE_SEARCH_LOCATION = 'CHANGE_SEARCH_LOCATION'

export const TOGGLE_SHOW = 'TOGGLE_SHOW';
export const MODAL_REFER = 'MODAL_REFER';
export const REFER_FRIEND_CHANGE = 'REFER_FRIEND_CHANGE';
export const REFER_FRIEND_CLEAR = 'REFER_FRIEND_CLEAR';


// Interest
export const CHANGE_DATA = 'CHANGE_DATA'
export const CHANGE_DATA_OBJECT = 'CHANGE_DATA_OBJECT'
export const CHANGE_DATE = 'CHANGE_DATE'
export const CHANGE_KNOWBY = 'CHANGE_KNOWBY'

export const CHECK_FORM_VALIDATION = 'CHECK_FORM_VALIDATION'

export const TOGGLE_STATE_DATA = 'TOGGLE_STATE_DATA'
export const ADD_FIELD = 'ADD_FIELD'
export const ADD_FIELD_OBJECT = 'ADD_FIELD_OBJECT'
export const POP_FIELD_OBJECT = 'POP_FIELD_OBJECT'


export const ADD_FILE = 'ADD_FILE'
export const ADD_FILE_OTHER = 'ADD_FILE_OTHER'
export const ADD_FILE_TO_STORE = 'ADD_FILE_TO_STORE'
export const ADD_COUNTER = 'ADD_COUNTER'
export const STORE_COOKIES = 'STORE_COOKIES'
export const LOAD_COOKIES = 'LOAD_COOKIES'


export const FILL_INTEREST = 'FILL_INTEREST';

export const ADDITEM_INTEREST = 'ADDITEM_INTEREST';

export const INITIAL_PERSONALITY = 'INITIAL_PERSONALITY';
export const INITIAL_ENGLISH = 'INITIAL_ENGLISH';

export const SET_VACANCY = 'SET_VACANCY'
export const SET_SEARCH_VACANCY = 'SET_SEARCH_VACANCY'

export const CLEAR_FILTERED_VACANCY = 'CLEAR_FILTERED_VACANCY'
export const LOAD_VACANCY = 'LOAD_VACANCY'

export const STORE_FORM_PROFILE = 'STORE_FORM_PROFILE'
export const LOAD_FORM_PROFILE = 'LOAD_FORM_PROFILE'

// test
export const INIT_TIMER_TEST = 'INIT_TIMER_TEST'
export const SET_TIMER_TEST = 'SET_TIMER_SET'
export const START_TIMER = 'START_TIMER'
export const STOP_TIMER = 'STOP_TIMER'

export const SET_PERSONALITY = 'SET_PERSONALITY'
export const SET_ENGLISH = 'SET_ENGLISH'
export const ANSWER_QUESTION = 'ANSWER_QUESTION'
export const SET_TEST_DONE = 'SET_TEST_DONE'

export const SUBMIT_DATA_TO_DB = 'SUBMIT_DATA_TO_DB'
export const CLEAR_LOCAL_STORAGE = 'CLEAR_LOCAL_STORAGE'
export const PROGRESS_UPLOAD_FORMDATA = 'PROGRESS_UPLOAD_FORMDATA'
