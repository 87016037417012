import * as actionTypes from './actionTypes'
import axios from 'axios'
import Moment from 'moment'
import history from '../history' 

export const setPersonality = (personality) =>{
  return{
    type: actionTypes.SET_PERSONALITY,
    listPersonality: personality
  }
}

export const setEnglish = (english) =>{
  return{
    type: actionTypes.SET_ENGLISH,
    listEnglish: english
  }
}

export const initPersonality = () =>{
  return dispatch => {
    axios.get('https://career.varnion.com/backoffice/api/questions/personality')
    .then(res=>{
      // console.log(res.data)
      let listPersonality = [...res.data]
      for(let i = 0; i < res.data.length; i++)
      {
        listPersonality[i] = {...listPersonality[i], answer: ""}
      }
      dispatch(setPersonality(listPersonality))
    })
    .catch(error=>{

    });
  }
}

export const initEnglish = () =>{
  return dispatch => {
    axios.get('https://career.varnion.com/backoffice/api/questions/english')
    .then(res=>{
      // console.log(res.data)

      let listEnglish = [...res.data]
      
      for(let i = 0; i < listEnglish.length; i++)
      {
        let answer = [];

        answer.push({id: 'a', answer: listEnglish[i].answer_a});
        answer.push({id: 'b', answer: listEnglish[i].answer_b});
        answer.push({id: 'c', answer: listEnglish[i].answer_c});
        answer.push({id: 'd', answer: listEnglish[i].answer_d});

        listEnglish[i] = {
          ...listEnglish[i],
          listAnswer: answer,
          answer: ""
        }
      }
      dispatch(setEnglish(listEnglish))
    })
    .catch(error=>{

    });
  }
}

export const initTimerTest = () =>{
  return dispatch => {
    axios.get('https://career.varnion.com/backoffice/api/timer')
    .then(res=>{
      dispatch(setTimer({personality: res.data.personality_timer, english: res.data.english_timer, counter: 0}))
    })
    .catch(error=>{
      console.log(error);
    });
  }
}

export const setTimer = (time) =>{
  return{
    type: actionTypes.SET_TIMER_TEST,
    timer: time
  }
}

export const answerQuestion = (data, fieldTest)=>{
  return{
    type: actionTypes.ANSWER_QUESTION,
    data: data,
    fieldTest: fieldTest
  }
}

export const progressUploadFormData = (progress, submitting) =>{
  return{
    type: actionTypes.PROGRESS_UPLOAD_FORMDATA,
    progress: progress,
    submitting: submitting
  }
}

export const setTestDone = (fieldTest, state) =>{
  return{
    type: actionTypes.SET_TEST_DONE,
    fieldTest: fieldTest,
    state: state
  }
}

export const clearLocalStorage = () =>{
  return{
    type: actionTypes.CLEAR_LOCAL_STORAGE
  }
}

export const submitDataToDB = (vacancy, dataInterest, dataQuestion) =>{
  return dispatch=>{
    let formData = new FormData();
    formData.append('vacancy', vacancy)
    formData.append('first_name', dataInterest.firstName.value)
    formData.append('last_name', dataInterest.lastName.value)
    formData.append('email', dataInterest.email.value)
    formData.append('phone', dataInterest.phone.value)

    if(dataInterest.highschoolName.value && dataInterest.highschoolLocation.value && dataInterest.highschoolYear.value)
    {
      formData.append('highschool', dataInterest.highschoolName.value)
      formData.append('highschool_location', dataInterest.highschoolLocation.value)
      formData.append('highschool_year', dataInterest.highschoolYear.value)
    }

    for(let i = 0; i < dataInterest.newUniv.length; i++)
    {
      if(dataInterest.newUniv[i].name.value.trim() !== '' && dataInterest.newUniv[i].location.value.trim() !== '' && dataInterest.newUniv[i].year.value.trim() !== '')
      {
        formData.append('universities[' + i + '][name]', dataInterest.newUniv[i].name.value)
        formData.append('universities[' + i + '][location]', dataInterest.newUniv[i].location.value)
        formData.append('universities[' + i + '][year]', dataInterest.newUniv[i].year.value)
      }
    }

    for(let i = 0; i < dataInterest.newExp.length; i++)
    {
      if(dataInterest.newExp[i].name.value.trim() !== '' && dataInterest.newExp[i].name.value.trim() !== '' && dataInterest.newExp[i].year.value.trim() !== '')
      {
        formData.append('experiences[' + i + '][name]', dataInterest.newExp[i].name.value)
        formData.append('experiences[' + i + '][position]', dataInterest.newExp[i].position.value)
        formData.append('experiences[' + i + '][periode]', dataInterest.newExp[i].year.value)
      }
    }

    formData.append('know_about_us', dataInterest.knowBy.value)
    formData.append('start_work', Moment(dataInterest.start.value).format('YYYY-MM-DD'));
    formData.append('expected_salary', dataInterest.salary.value)
    formData.append('cv', dataInterest.attachment.cv.file)
    formData.append('avatar', dataInterest.attachment.avatar.file)

    let otherAttachment = []
    for(let i = 0; i < dataInterest.attachment.other.length; i++)
    {
      if(dataInterest.attachment.other[i].file !== null && dataInterest.attachment.other[i].preview !== null)
      {
        // otherAttachment.push(dataInterest.attachment.other[i].file)
        formData.append('attachment[' + i + ']', dataInterest.attachment.other[i].file)
      }
    }
    // formData.append('attachment', otherAttachment)
    
    

    let english = []
    for(let i = 0; i < dataQuestion.english.length; i++)
    {
      english.push({question_id: parseInt(dataQuestion.english[i].id), answer: dataQuestion.english[i].answer})
    }
    formData.append('english', JSON.stringify(english))

    let personality = []
    for(let i = 0; i < dataQuestion.personality.length; i ++)
    {
      personality.push({question_id: parseInt(dataQuestion.personality[i].id), answer: dataQuestion.personality[i].answer})
    }
    formData.append('personality', JSON.stringify(personality))
    
    dispatch(progressUploadFormData(1, true))
    axios({ 
        method: 'POST', 
        url: 'https://career.varnion.com/backoffice/api/applicant/apply',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // onUploadProgress: (progressEvent) => {
        //   const { loaded, total } = progressEvent;
        //   let percentComplete = loaded / total * 100;
        //   dispatch(progressUploadFormData(percentComplete, true))
        //   dispatch(setTestDone("english", true))
        // },
      })
      .then(res=>{
        dispatch(progressUploadFormData(100, true))
        dispatch(setTestDone("english", true))
        dispatch(clearLocalStorage())
        setTimeout(() => {
          dispatch(progressUploadFormData(0, false))
          history.replace('/test/thankyou')
        }, 1000);
      })
      .catch(error=>{
        console.log(error);
        console.log("error");
    });
    
  }
}