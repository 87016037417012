import React from 'react'

import classes from './English.module.scss'
import arrow from '../../../assets/img/arrow.png'
import iconLeft from '../../../assets/img/icon-quiz-1.png'
import iconRight from '../../../assets/img/icon-quiz-2.png'

import Aux from '../../../hoc/Aux'
const Intro = (props) => {
  return (
    <Aux>
      <div className={classes.introEnglish}>
        <h4>One last thing - <span className={classes.lineTemp}>Don't leave just yet !</span></h4>
        <p>We’d like to know your english proficiency by taking this brief quiz. <span className={classes.lineBreak}></span> The question are equipped with multiple choice. With only one right answer.</p>
        <h5>You only have 20 minutes to do this quiz. <span className={classes.lineBreak}></span> so, do your best !</h5>
        <h2>ARE YOU READY ?</h2>
        <div className={classes.imgLeft + ' ' + classes.icon}>
          <img src={iconLeft} />
        </div>
        <div className={classes.imgRight + ' ' + classes.icon}>
          <img src={iconRight}/>
        </div>
      </div>

      <div className={classes.selectionBox + ' ' + classes.end + ' ' + classes.intro}>
        <a href="/" className={classes.next} style={{backgroundImage: 'url('+ arrow +')'}}
        onClick={props.startTest}>YES I'M READY</a>
      </div>
    </Aux>
  )
}

export default Intro
