import React from 'react'
import classes from './Navbar.module.scss'
import Transition from 'react-transition-group/Transition'
import logo from '../../assets/img/Varnion-Logo_White.png'

const Navbar = (props) => {
  return (
    <Transition in={props.state} timeout={0} mountOnEnter unmountOnExit>
      {
        <div className={classes.navbar} style={{
          opacity: props.state === 'entering'? 0 : 1, 
          background: props.transparent ? "#3B88B9":null,
          transitionDuration: '1s'
          }}>
          <ul>
            <li className={classes.logo} key="logo">
              <div className={classes.logoContainer}>
                <img onClick={props.backHome} src={logo} alt="logo varnion"></img>
              </div>
            </li>
          </ul>
        </div>
      }
    </Transition>
  )
}

export default Navbar
