import React from 'react'
import classes from './NotFound.module.scss';

const notFound = () => {
  return (
    <div className={classes.container}>
      <h1><span>404.</span> Page not found</h1>
      
    </div>
  )
}

export default notFound
