import React from 'react'

import classes from './ReferFriend.module.scss'
import Transition from 'react-transition-group/Transition'

import Input from '../UI/Input/Input'


const ReferFriend = (props) => {
  // console.log(props);
  
  return (
    <Transition in={props.dataItem.refer} timeout={0} mountOnEnter unmountOnExit>
      {
        state=>(
          <div className={classes.referContainer} 
          style={{
            opacity: state === 'entering'? 0 : 1, 
            transitionDuration: '1s',
            }}>
            <div className={classes.boxRefer}>
              <div className={classes.btnClose} onClick={() => {props.modalRefer(false); props.referClear(props.index, props.stateVacancy)}}></div>
              <p className={classes.referInfo}><span>Referring :</span> {props.dataItem.title} <span> at </span> {props.dataItem.location_name}</p>
              <form onSubmit={(e)=>props.onRefer(e, props.dataItem.slug)}>
                <Input field="name" data={props.referData} change={props.referChange} 
                touched={props.referData.name.touched} 
                invalid={!props.referData.name.valid} 
                feedback={props.referData.name.feedback}/>
                <Input field="email" data={props.referData} change={props.referChange} 
                touched={props.referData.email.touched} 
                invalid={!props.referData.email.valid} 
                feedback={props.referData.email.feedback}/>
                <Input field="phone" data={props.referData} change={props.referChange} 
                touched={props.referData.phone.touched} 
                invalid={!props.referData.phone.valid} 
                feedback={props.referData.phone.feedback}/>
                <span className={classes.btnContainer + ' ' + [props.referState ? null : classes.disabled]}><button>Submit</button></span>
              </form>
            </div>
          </div>
        )
      }
    </Transition>
  )
}

export default ReferFriend
