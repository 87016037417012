import React from 'react'
import ListPersonality from './ListPersonality'

import Aux from '../../../hoc/Aux'
import classes from './Personality.module.scss'

import arrow from '../../../assets/img/arrow.png'

const TestPersonality = (props) => {
  // console.log(props);

  return (
    <Aux>
      {
        props.listPersonality ? 
        <Aux>
        {
          props.listPersonality.map((item,index) =>(
            parseInt(props.path) === parseInt(item.id) ?
            <ListPersonality key={item.id} changeHandler={props.changeHandler} index={index} answer={item.answer} id={item.id} question={item.question} /> : null
          ))
        }
        {
          props.path === "intro" ? null :
          <div className={classes.selectionBox}>
            <a href="/" className={[parseInt(props.path) === 1 ? classes.hide : ''] + ' ' + classes.prev } 
            onClick={(e)=>props.navPersonality(e,'prev')}>BACK<span style={{backgroundImage: 'url('+ arrow +')'}}></span></a> 
            <a href="/" className={[parseInt(props.path) === parseInt(props.listPersonality.length) ? classes.hideNone : ''] + ' ' + classes.next } 
            onClick={(e)=>props.navPersonality(e,'next')}>NEXT<span style={{backgroundImage: 'url('+ arrow +')'}}></span></a> 
            <a href="/" className={[parseInt(props.path) === parseInt(props.listPersonality.length) ? '' : classes.hideNone] + ' ' + classes.next} 
            onClick={(e)=>props.navPersonality(e, 'toPG')}>SUBMIT<span style={{backgroundImage: 'url('+ arrow +')'}}></span></a>
          </div>
        }
        </Aux> 
        : null
      }
    </Aux>
  )
}

export default TestPersonality
