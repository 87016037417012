import * as actionTypes from './actionTypes'
import axios from 'axios'
import {setLoader, setStateLoader} from './index'

export const initHomeContent = () =>{
  return dispatch =>{
    axios({ 
      method: 'GET', 
      url: 'https://career.varnion.com/backoffice/api/pageContents',
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentComplete = loaded / total * 100;
        dispatch(setLoader(parseInt(percentComplete/2)));
        console.log(parseInt(percentComplete/2));
        },
      })
      .then(res=>{
        dispatch(setHomeContent(res.data.contents));
        setTimeout(() => {
          dispatch(setStateLoader(false))
        }, 1000);
      })
      .catch(error=>{
        console.log(error);
    });
  }
}

export const setHomeContent = (contents) =>{
  return {
    type: actionTypes.SET_HOME_CONTENT,
    contents: contents
  }
}

export const scrollVacancy = (payload) =>{
  return {
    type: actionTypes.SCROLL_VACANCY,
    payload: payload
  }
}

export const filteredVacancy = (search) =>{
  return {
    type: actionTypes.FILTERED_VACANCY,
    search: search
  }
}

export const addItemInterest = () =>{
  return {
    type: actionTypes.ADDITEM_INTEREST
  }
}

export const initialPersonality = () =>{
  return {
    type: actionTypes.INITIAL_PERSONALITY
  }
}

export const initialEnglish = () =>{
  return {
    type: actionTypes.INITIAL_ENGLISH
  }
}