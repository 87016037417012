import React from 'react'
import classes from './Loader.module.scss'

import logoVarnion from '../../../assets/img/Varnion-Logo_White.png'

const Spinner = (props) => {
  return (
    <div className={classes.spinnerContainer}>
      <div className={classes.imgContainer}>
        <img src={logoVarnion} alt="logo"></img>
      </div>
      <div className={classes.loadingBar}>
        <span style={{width: props.loadingBar + '%'}}></span>
      </div>
    </div>
  )
}

export default Spinner
