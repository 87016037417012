import React from 'react'

import classes from './English.module.scss'

import {englishChecked} from '../../../store/utility'
import Aux from '../../../hoc/Aux'

const ListEnglish = (props) => {
  // console.log(props);

  return (
    <Aux>
      <div className={classes.testContainer}>
        <p className={classes.number}>{props.id}.</p>
        <span dangerouslySetInnerHTML={{
          __html: props.question
        }}/>

        <form className={classes.answerForm}>
          {
            props.listAnswer.map((item, index)=>{
              return (
                <div className={classes.answerList} key={item.id}>
                  <input onChange={(e)=>props.changeHandler(e, item.id, props.indexQuestion)} defaultChecked={index === englishChecked(props.answer)} type="radio" id={['answer-' + item.id]} name="education" />
                  <label htmlFor={['answer-' + item.id]}>{item.answer}</label>
                </div>
              )
            })
          }
        </form>
      </div>
      
    </Aux>
  )
}

export default ListEnglish
