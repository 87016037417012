import * as actionTypes from './actionTypes'

import axios from 'axios'
import { initPersonality, initEnglish, initTimerTest } from './test';

export const setLoader = (amount) =>{
  return{
    type: actionTypes.SET_LOADER,
    amount: amount
  }
}
export const setStateLoader = (state) =>{
  return{
    type: actionTypes.SET_STATE_LOADER,
    state: state
  }
}

export const toggleShow = (index, stateVacancy) =>{
  return {
    type: actionTypes.TOGGLE_SHOW,
    index: index,
    stateVacancy: stateVacancy
  }
}

export const toggleRefer = (index, stateVacancy) =>{
  return {
    type: actionTypes.TOGGLE_REFER,
    index: index,
    stateVacancy: stateVacancy
  }
}

export const setVacancy = (vacancy, stateMore, methodSet) =>{
  return{
    type: actionTypes.SET_VACANCY,
    listVacancy: vacancy,
    stateMore: stateMore,
    methodSet: methodSet
  }
}

export const initVacancy = () =>{
  return dispatch => {
    axios({ 
      method: 'GET', 
      url: 'https://career.varnion.com/backoffice/api/vacancy/list?limit=6&offset=0',
      // headers: {'X-Requested-With': 'XMLHttpRequest'},
      
      // responseType: 'stream',
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentComplete = loaded / total * 100;
        
        dispatch(setLoader(parseInt(percentComplete/2)));
        console.log(parseInt(percentComplete/2));
        
        },
      })
      .then(res=>{
        let listVacancy = [...res.data]
        
        listVacancy.map((item) => (
          item.show = false,
          item.refer = false
        ))
        
        if(res.data.length > 5)
        {
          dispatch(setVacancy(listVacancy, true, "init"))
          listVacancy.pop();
        }
        else
        {
          dispatch(setVacancy(listVacancy, false, "init"))
        }

        setTimeout(() => {
          dispatch(setStateLoader(false))
          dispatch(initPersonality())
          dispatch(initEnglish())
          dispatch(initTimerTest())
        }, 1000);
      })
      .catch(error=>{
        console.log(error);
    });
  
  }
}

export const loadVacancy = (length) =>{
  return dispatch => {
    axios.get('https://career.varnion.com/backoffice/api/vacancy/list?limit=6&offset=' + length)
    .then(res=>{
      let listVacancy = [...res.data]
      listVacancy.map((item) => (
        item.show = false,
        item.refer = false
      ))
      if(res.data.length > 5)
      {
        dispatch(setVacancy(listVacancy, true, "addMore"))
      }
      else
      {
        dispatch(setVacancy(listVacancy, false, "addMore"))
      }
    })
    .catch(error=>{

    });
  }
}

export const changeSearchLocation = (location)=>{
  return{
    type:actionTypes.CHANGE_SEARCH_LOCATION,
    location: location
  }
}

export const setSearchVacancy = (keyword, location, vacancy) =>{
  return{
    type: actionTypes.SET_SEARCH_VACANCY,
    searchKeyword: keyword,
    searchLocation: location,
    filteredVacancy: vacancy,
  }
}

export const clearFilteredVacancy = () =>{
  return{
    type: actionTypes.CLEAR_FILTERED_VACANCY,
    searchKeyword: null,
    searchLocation: 0,
    filteredVacancy: []
  }
}

export const searchVacancy = (keyword, location) =>{
  if(keyword.length<1 && location === "0")
  {
    return dispatch=>{
      dispatch(setSearchVacancy(null, location, []))
    }
  }

  if(location === "0")
  {
    return dispatch=>{
      axios.get('https://career.varnion.com/backoffice/api/vacancy/search?query='+ keyword )
      .then(res=>{
        dispatch(setSearchVacancy(keyword, location, res.data))
      })
      .catch(error=>{
  
      });
    }
  }

  if(keyword.length<1)
  {
    return dispatch => {
      axios.get('https://career.varnion.com/backoffice/api/vacancy/search?location=' + location)
      .then(res=>{
        dispatch(setSearchVacancy(null, location, res.data))
      })
      .catch(error=>{
  
      });
    }
  }
  
  if(keyword.length>0){
    return dispatch => {
      axios.get('https://career.varnion.com/backoffice/api/vacancy/search?query='+ keyword  +'&location=' + location)
      .then(res=>{
        dispatch(setSearchVacancy(keyword, location, res.data))
      })
      .catch(error=>{
  
      });
    }
  }
}

export const modalRefer = (state)=>{
  return {
    type: actionTypes.MODAL_REFER,
    state: state
  }
}

export const referFriendChange = (dataItem) =>{
  return{
    type: actionTypes.REFER_FRIEND_CHANGE,
    dataItem: dataItem
  }
}

export const referFriendClear = () =>{
  return{
    type: actionTypes.REFER_FRIEND_CLEAR
  }
}

export const referFriend = (dataItem) =>{
  console.log(dataItem);
  let formData = new FormData();
  formData.append('vacancy', dataItem.vacancy)
  formData.append('name', dataItem.name.value)
  formData.append('email', dataItem.email.value)
  formData.append('phone', dataItem.phone.value)

  return dispatch => {
    // axios.post('http://career1.varnion.com/backoffice/api/applicant/refer', formData, {
    //   onUploadProgress: progressEvent=>{
    //     console.log(progressEvent);
    //   }
    // })
    // .then(res=>{
    //   console.log(res)
    //   console.log("success")
    // })
    // .catch(error=>{
    //   console.log(error);
    //   console.log("error");
    // });

    axios({ 
      method: 'POST', 
      url: 'https://career.varnion.com/backoffice/api/applicant/refer',
      data: formData,
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      // headers: {'X-Requested-With': 'XMLHttpRequest'},
      // onUploadProgress: (progressEvent) => {
      //   const { loaded, total } = progressEvent;
      //   console.log(total);
        
        
      //   // dispatch(updateImageUploadProgress(loaded, total));
      //   console.log(loaded);
      //   console.log(total);
      //   console.log(progressEvent);
        
        
      //   },
      })
      .then(res=>{
        setTimeout(() => {
          dispatch(referFriendClear());
          dispatch(toggleRefer());
        }, 500);
      })
      .catch(error=>{
        console.log(error);
        console.log("error");
    });


  }
}

export const fillInterest = (item) =>{
  return {
    type: actionTypes.FILL_INTEREST,
    payload: item
  }
}

export const storeFormProfile = (dataForm) =>{
  return {
    type: actionTypes.STORE_FORM_PROFILE,
    dataForm : dataForm
  }
}

export const loadDataApplicant = () => {
  return {
    type: actionTypes.LOAD_FORM_PROFILE
  }
}