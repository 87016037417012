import React, {Component} from 'react'
import {Route} from 'react-router-dom'

import {connect} from 'react-redux'

import * as actions from '../../../store/actions/index'

import classes from './Personality.module.scss'

import Aux from '../../../hoc/Aux'
import IntroPersonality from '../../../components/Test/Personality/Intro' 
import TestPersonality from '../../../components/Test/Personality/TestPersonality'

class Personality extends Component {
  
  componentDidMount(){
    this.props.history.replace( '/personality/intro');

    // this.props.onInitPersonality();
  }

  startTest=(event)=>{
    event.preventDefault();
    this.props.history.replace( '/test/personality/1');
  }

  prevPersonality=(event)=>{
    event.preventDefault();
    const prev = parseInt(this.props.history.location.pathname.split('/').pop()) - 1
    this.props.history.replace( '/test/personality/' + prev)
  }
  nextPersonality=(event)=>{
    event.preventDefault();
    const next = parseInt(this.props.history.location.pathname.split('/').pop()) + 1
    this.props.history.replace( '/test/personality/' + next)
  }

  changeHandler = (e, id)=>{
    let answer = e.target.value;
    console.log(answer);
    
    this.props.onAnswerPersonality({id: id, answer: answer}, "Personality");
  }

  navPersonality = (event, state)=>{
    event.preventDefault();

    let operator = 0;

    switch(state)
    {
      case ('prev'):
        operator = -1
        break;
      case('next'):
        operator = 1
        break;
      default:
        operator = 0
    }

    if(state !== 'toPG')
    {
      const path = parseInt(this.props.history.location.pathname.split('/').pop()) + parseInt(operator)
      return this.props.history.replace( '/test/personality/' + path)
    }
    if(state === 'toPG')
    {
      return this.props.history.replace('/test/english')
    }
    
  }

  render(){

    const path = this.props.history.location.pathname;
    const slug = path.split('/').pop();
    console.log(slug);
    
    // console.log(this.props.listQuestionPersonality);
    

    return(
      <Aux>
        <Route path="/test/personality/intro" exact render={IntroPersonality} />

        <Route path="/test/personality" render={()=>
          <TestPersonality path={slug} changeHandler={this.changeHandler} listPersonality={this.props.listQuestionPersonality} />
        }/>

        <div className={classes.selectionBox + ' ' + [slug === 'intro'? classes.end : '']}>
          <Route path="/test/personality/" render={()=>
            slug !== "intro" && this.props.listQuestionPersonality !== null ?
              <Aux>
                <a href="/" className={[parseInt(slug) === 1 ? classes.hide : ''] + ' ' + classes.prev } 
                onClick={(e)=>this.navPersonality(e,'prev')}>BACK</a> 
                <a href="/" className={[parseInt(slug) === parseInt(this.props.listQuestionPersonality.length) ? classes.hide : ''] + ' ' + classes.next } 
                onClick={(e)=>this.navPersonality(e,'next')}>NEXT</a> 
                <a href="/" className={[parseInt(slug) === parseInt(this.props.listQuestionPersonality.length) ? '' : classes.hideNone] + ' ' + classes.next} 
                onClick={(e)=>this.navPersonality(e, 'toPG')}>SUBMIT</a>
              </Aux>
              : 
              <Route path="/test/personality/intro" exact render={()=> 
                <a href="/" className={classes.next} onClick={this.startTest}>YES I'M READY</a>} 
              />
            } 
          />
        </div>
      </Aux>
    )
  }
}

const mapStateToStore = (state) =>{
  return{
    listQuestionPersonality: state.test.listQuestionPersonality
  }
}

const mapDispatchToStore = (dispatch) => {
  return {
    onInitPersonality: ()=> dispatch(actions.initPersonality()),
    onAnswerPersonality: (data, fieldTest)=>dispatch(actions.answerQuestion(data, fieldTest))
  }
}

export default connect(mapStateToStore, mapDispatchToStore)(Personality);
