import React from 'react'

import thankyou from '../../assets/img/icon-quiz-3.png'

import classes from './Thankyou.module.scss';

const Thankyou = () => {
  return (
    <div className={classes.thankyouContainer}>
      <h2>THANK YOU!</h2>
      <p>We'll definitely get back to you as possible <span className={classes.linebreak}></span>
      if your submission fits to the our requirements</p>
      <div className={classes.imgContainer}>
        <img src={thankyou} alt=""></img>
      </div>
    </div>
  )
}

export default Thankyou
