import React from 'react'
import classes from './Input.module.scss'

import moneyFormatter from 'money-formatter'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import Aux from '../../../hoc/Aux'

import blueArrow from '../../../assets/img/blue-arrow.png'

const input = (props) =>{
  let inputElement = null;
  
  const dataArray = {...props.data};
  const fieldData = {...dataArray[props.field]};
  const inputClasses = [];

  if(props.touched && props.invalid)
  {
    inputClasses.push(classes.invalid);
  }
  if(props.touched && !props.invalid)
  {
    inputClasses.push(classes.valid);
  }

  const fieldKey = Object.keys(dataArray);
  const index = fieldKey.findIndex(x=> x === props.field);

  switch(props.field)
  {
    case 'start' :
      inputElement = <DatePicker 
      selected={new Date(fieldData.value)}
      minDate={new Date()}
      onChange={props.changeDate} />
      break;
    case 'switchState' :
      inputElement = <li className={classes.radioInput}>
            {
              props.checked === "true" ? 
              <input type="radio" defaultChecked id={props.id} name={props.name} />
              :
              <input type="radio" id={props.id} name={props.name} />
            }
            <label onClick={()=>props.changeStateData(props.state, props.name)} htmlFor={props.id}>{props.value}</label>
            <div className={classes.check}></div>
          </li>
      break;
    case 'selection' :
      inputElement = <ul 
        className={classes.knowBy + ' ' + [props.modalSelection ? classes.show : '']}>
          {props.selectionData.map((item)=>(
            <li onClick={()=>props.changeSelectionKnow(item.value, item.displayValue)} key={"item" + item.value}>{item.displayValue}</li>
          ))}
          <span className={classes.blueArrow} style={{backgroundImage: 'url('+ blueArrow +')'}}></span>
        </ul> 
        break;
    case 'fieldObject' :
      inputElement = <input 
        {...props.data.elementConfig}
        className={inputClasses.join(' ')}
        value={props.data.value}
        name={props.name}
        onChange={(e)=>props.changeNew(e, props.fieldName, props.index)}
      />
      break;
    case 'salary' :
      inputElement = <input
      className={inputClasses.join(' ')}
      name={fieldKey[index]}
      {...fieldData.elementConfig}
      value={fieldData.value}
      onChange={props.change} />;
      break;
    case 'year' :
      inputElement = <DatePicker
      selected={new Date()}
      onChange={props.changeDate}
      dateFormat="MM/yyyy"
      showMonthYearPicker
  />
      break;
    default:
      inputElement = <input
      className={inputClasses.join(' ')}
      name={fieldKey[index]}
      {...fieldData.elementConfig}
      value={fieldData.value}
      onChange={props.change} />;
  }

  return(
    <Aux>
      <span className={classes.inputElement}>
        {inputElement}
        {
          props.touched && props.invalid ?
          <span className={classes.feedback}>
            <p>{props.feedback}</p>
          </span>
          : null
        }
      </span>
    </Aux>
  )
}

export default input;