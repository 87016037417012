import React from 'react'

import classes from './Vacancy.module.scss'

const DetailVacancy = (props) => {
  return (
    <div className={classes.detailInfo + ' ' + [props.dataItem.show ? classes.show : classes.hide]}>
      <h4 className={classes.title}>{props.dataItem.title}</h4>
      <span className={classes.jobDesc} dangerouslySetInnerHTML={{
        __html: props.dataItem.job_description
      }}></span>
      <span className={classes.require} dangerouslySetInnerHTML={{
        __html: props.dataItem.requirement
      }}></span>
      <div className={classes.btnSelection}>
        <button className={classes.interest} onClick={(e)=>props.interestVacancy(e, props.dataItem, true)}>I'm Interested</button>
        <button className={classes.refer} onClick={() => {props.modalRefer(true) ; props.toggleRefer(props.index, props.stateVacancy)}}>Refer A Friend</button>
      </div>
    </div>
  )
}

export default DetailVacancy
