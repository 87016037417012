import React, { Component } from 'react'

// store purpose
import {connect} from 'react-redux'
import * as actions from '../../store/actions/index'

//animation
import Transition from 'react-transition-group/Transition'

// style
import classes from './Interest.module.scss'

// container
import Upload from './UploadInterest/Upload'

// component
import Aux from '../../hoc/Aux'
import FormInput from '../../components/Interest/FormInput/FormInput'
import Preview from '../../components/Interest/FilePreview/FilePreview'
import Navbar from '../../components/Navbar/Navbar'

class Interest extends Component {
  state = {
    initial: false,
    modalSelection : false,
  }

  switchStateModalSelection = (props)=>{
    switch(props)
    {
      case "false" :
        return this.setState(state=>({
          ...state,
          modalSelection: false
        }))
      default: 
        return this.setState(state=>({
          ...state,
          modalSelection : !this.state.modalSelection
        }))
    }
  }

  onChangeHandler=(e)=>{
    const name = e.target.name;
    const value = e.target.value;
    this.props.changeData(name,value);
    this.props.checkFormValidation();
  }
  onChangeHandlerNew=(e, fieldname, index)=>{
    const name = e.target.name;
    const value = e.target.value;
    this.props.changeDataObject(fieldname, index, {name: name, value: value});
    this.props.checkFormValidation();
  }

  handleChangeDate = (date) => {
    this.props.changeData("start", date);
  }

  changeSelectionKnow = (value, display) =>{
    this.switchStateModalSelection("false");
    this.props.changeKnowBy(value, display);
  }

  addInput = (e, props) =>{
    e.preventDefault();
    this.props.addField(props);
  }

  addFieldObj = (e, fieldname)=>{
    e.preventDefault();
    this.props.addFieldObj(fieldname)
  }

  popFieldObj = (e, fieldname, index)=>{
    e.preventDefault();
    this.props.popFieldObj(fieldname, index)
  }

  onSubmit=(e)=>{
    e.preventDefault();
    if(this.props.dataApplicant.formValid)
    {
      this.props.storeCookies(this.props.dataApplicant);
      this.props.setTimer({personality: null, english: null, counter: 1000 * 60 * this.props.timer.personality})
      this.props.history.replace( '/test/personality/intro');
    }
  }

  changeStateData=(state, field)=>{
    this.props.changeStateData(state,field);
    this.props.checkFormValidation();
  }

  addFile=(file, method, index)=>{
    this.props.addFile(file, method, index);
    setTimeout(()=>{
      this.props.checkFormValidation();
    },700)
  }

  backHome=()=>
  {
    window.location.replace('/');
  }

  componentDidMount = () =>{
    this.setState({
      initial : true
    })
    window.scrollTo(0, 0);

    if(this.props.interestVacancy === null)
    {
      this.props.history.replace( '/');
    }
    if(this.props.interestVacancy !== null)
    {
      let queryParams = []
      queryParams.push(encodeURIComponent(this.props.interestVacancy))
      
      let queryString = queryParams.join('&')
      this.props.history.replace({
        pathname: '/interest',
        hash: queryString
      })

      const slug = this.props.history.location.hash.split('#').pop();
      
      this.setState({
        dataUserInterest : {
          ...this.state.dataUserInterest,
          slug : slug
        }
      })
    }

  }

  render() {
    return (
      <div>
        <Transition in={this.state.initial} timeout={0} mountOnEnter unmountOnExit>
          {
            state=>(
              <Aux>
                <Navbar backHome={this.backHome} state={state}/>
                <div className={classes.interestForm} style={{opacity: state === 'entering'? 0 : 1, transitionDuration: '1s'}}>
                  <Preview cv={this.props.dataApplicant.attachment.cv} avatar={this.props.dataApplicant.attachment.avatar} otherAttachment={this.props.dataApplicant.attachment.other} />
                  {/* <Preview dataApplicant={this.props.dataApplicant}/> */}
                  <Upload cv={this.props.dataApplicant.attachment.cv} avatar={this.props.dataApplicant.attachment.avatar} otherAttachment={this.props.dataApplicant.attachment.other} addFile={this.addFile} addResume={this.addResume} addAvatar={this.addAvatar} />
                  <FormInput 
                    submit={this.onSubmit}
                    addInput={this.addInput}
                    dataApplicant={this.props.dataApplicant}
                    change={this.onChangeHandler}
                    changeDate={this.handleChangeDate}
                    changeStateData = {this.changeStateData}
                    switchStateModalSelection={this.switchStateModalSelection}
                    changeSelectionKnow= {this.changeSelectionKnow}
                    modalSelection={this.state.modalSelection}
                    closeModalSelection={()=>this.switchStateModalSelection("false")}

                    changeNew={this.onChangeHandlerNew}
                    addFieldObj={this.addFieldObj}
                    popFieldObj={this.popFieldObj}
                  />
                </div>
              </Aux>
              
            )
          }
        </Transition>
      </div>
    )
  }
}

const mapStateToStore = (state) => {
  return{
    dataApplicant: state.interest.dataInterest,
    interestVacancy: state.base.interestVacancy,
    timer: state.test.timer
  }
}

const mapDispatchToStore = (dispatch) => {
  return {
    onInterestVacancy: (item)=> dispatch(actions.fillInterest(item)),
    storeFormProfile: (data)=>dispatch(actions.storeFormProfile(data)),

    changeData: (name,data)=>dispatch(actions.changeData(name, data)),
    changeDataObject: (fieldname,index,data)=>dispatch(actions.changeDataObject(fieldname,index,data)),
    checkFormValidation: ()=>dispatch(actions.checkFormValidation()),
    addField: (array, field)=>dispatch(actions.addField(array, field)),
    addFieldObj: (field)=>dispatch(actions.addFieldObj(field)),
    popFieldObj:(field, index)=>dispatch(actions.popFieldObj(field, index)),

    addFile: (file, method, index)=>dispatch(actions.addFile(file,method, index)),

    changeStateData: (state, field)=>dispatch(actions.changeStateData(state, field)),
    changeKnowBy: (value,display)=>dispatch(actions.changeKnowBy(value, display)),
    storeCookies: (data)=>dispatch(actions.storeCookies(data)),
    setTimer: (time)=>dispatch(actions.setTimer(time))
  }
}

export default connect(mapStateToStore, mapDispatchToStore)(Interest);