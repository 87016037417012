import React, { Component } from 'react'
import {Route} from 'react-router-dom'

import {connect} from 'react-redux'
import * as actions from '../../../store/actions/index'

// import classes from './English.module.scss'
import Aux from '../../../hoc/Aux'

import IntroEnglish from '../../../components/Test/English/Intro'
import TestEnglish from '../../../components/Test/English/TestEnglish'

class English extends Component {
  
  
  componentWillMount()
  {
    if(this.props.listQuestionEnglish === null)
    {
      this.props.history.replace( '/')
    }
  }
  componentDidMount()
  {
    if(this.props.testDone.english)
    {
      this.props.history.replace( '/test/thankyou')
    }
  }
  
  startTimer=(time)=>{
    time = time * 1000 * 60;
    this.timer = setInterval(()=>{
      time = time - 1000
      this.props.setTimer({personality: null, english: null, counter: time})

      if(time === 0)
      {
        this.props.setTestDone('english', true)
        this.props.setTimer({personality: null, english: null, counter: 0})
        clearInterval(this.timer);
        this.submitHandler();
      }
    }, 1000)
  }

  startTest=(event)=>{
    event.preventDefault();
    this.startTimer(this.props.timer.english)
    this.props.history.replace( '/test/english/1');
  }

  navEnglish = (event, state)=>{
    event.preventDefault();
    let operator = 0;

    switch(state)
    {
      case ('prev'):
        operator = -1
        break;
      case('next'):
        operator = 1
        break;
      default:
        operator = 0
    }

    if(state !== 'thankyou')
    {
      const path = parseInt(this.props.history.location.pathname.split('/').pop()) + parseInt(operator)
      return this.props.history.replace( '/test/english/' + path)
    }
    if(state === 'thankyou')
    {
      this.props.setTestDone('english', true)
      this.props.setTimer({personality: null, english: null, counter: 0})
      clearInterval(this.timer);
      this.submitHandler();
    }
  }

  changeHandler = (e, id, index)=>{
    this.props.onAnswerEnglish({id: id, index: index}, "English");
  }

  submitHandler=()=>{
    this.props.submitDataToDB(this.props.vacancy, this.props.dataApplicant, this.props.dataQuestion)
  }
  
  render() {
    const path = this.props.history.location.pathname;
    const slug = path.split('/').pop();

    return (
      <Aux>
        <Route exact path={`${this.props.match.path}/intro`} render={()=>
          <IntroEnglish startTest={this.startTest} />
        } />

        <Route path="/test/english" render={()=>
          <TestEnglish formSubmitting={this.props.formSubmitting} progress={this.props.progress} path={slug} navEnglish={this.navEnglish} listEnglish={this.props.listQuestionEnglish} changeHandler={this.changeHandler} submitHandler={this.submitHandler} />
        }/>
      </Aux>
    )
  }
}

const mapStateToStore = (state) =>{
  return{
    vacancy: state.base.interestVacancy,
    listQuestionEnglish: state.test.listQuestionEnglish,
    dataApplicant: state.interest.dataInterest,
    dataQuestion: {personality: state.test.listQuestionPersonality, english: state.test.listQuestionEnglish},
    formSubmitting: state.test.submitting,
    progress: state.test.progressUpload,

    testDone: state.test.testDone,
    timer: state.test.timer
  }
}

const mapDispatchToStore = (dispatch) => {
  return {
    onInitEnglish : () => {
      dispatch(actions.initEnglish())
    },
    onAnswerEnglish: (data, fieldTest)=>dispatch(actions.answerQuestion(data, fieldTest)),
    setTimer:(time)=>dispatch(actions.setTimer(time)),
    setTestDone: (fieldTest, state)=>dispatch(actions.setTestDone(fieldTest, state)),
    submitDataToDB: (vacancy, dataInterest, dataQuestion)=>dispatch(actions.submitDataToDB(vacancy, dataInterest, dataQuestion))
  }
}

export default connect(mapStateToStore, mapDispatchToStore)(English);
