import React, { Component } from 'react'
// import {Route} from 'react-router-dom'

// store purpose
import {connect} from 'react-redux'
import * as actions from '../../store/actions/index'

import * as baseActions from '../../store/actions/index'

//containers
import Vacancy from '../Vacancy/Vacancy'

//component
import VCC from '../../components/VCC/VCC'
// import Timer from '../Timer/Timer'

import Aux from '../../hoc/Aux'
import Loader from '../../components/UI/Loader/Loader'

// css
import './home.scss'

//animation
import Transition from 'react-transition-group/Transition'

// img
import logo from '../../assets/img/Varnion-Logo_White.png'
import homeLogo from '../../assets/img/home.png'

class home extends Component {
  stop = (e) => {
    e.stopPropagation();
  }
  
  gotoVCC = (event) =>
  {
    event.preventDefault();
    window.location.href = 'http://www.varnion.com/invarnion/ourculture';
  }

  logClick = () => {
    this.props.log()
  }

  scrollToVacancy = (e) => {
    e.preventDefault();
    const topVacancy = this.vacancyRef.current.offsetTop;
    const navbarHeight = this.navbarRef.current.clientHeight;
    this.props.scroll({topVacan: topVacancy, navHeight: navbarHeight})
  }

  onInterest = (event) =>{
    event.preventDefault();
    this.props.history.push( '/interest');
  }

  onSearchHandler = (keyword) => {
    this.props.filterVacancy({search: keyword})
  }

  vacancyRef = React.createRef()
  navbarRef = React.createRef()

  componentDidMount()
  {
    window.scrollTo(0, 0);
    this.props.onInitVacancy();
    this.props.onInitContent();
    
    this.props.setTestDone('personality', false)
    this.props.setTestDone('english', false)
  }

  render() {
    const uploadUrl = "https://career.varnion.com/backoffice/uploads/"

    return (
      <Aux>
        <Transition in={this.props.loadingState === true} timeout={0} mountOnEnter unmountOnExit>
          {
            <Loader loadingBar={this.props.loadingBar}/>
          }
        </Transition>

        <Transition in={this.props.loadingState === false} timeout={0} mountOnEnter unmountOnExit>
          {state=>(
            <div className="container" style={{opacity: state === 'entering'? 0 : 1, transitionDuration: '1s'}}>
            {/* <div className="container"> */}
              <div className="navbar" ref={this.navbarRef}>
                <ul>
                  <li className="logo" key="logo">
                    <div className="logo-container">
                      <img onClick={()=>window.location.replace('/')} src={logo} alt="logo varnion"></img>
                    </div>
                  </li>
                  {/* <Timer /> */}
                  <div className="navbar-menu">
                    <li className="vacancy-link" key="vacancy"><a href="/" onClick={(e)=>this.scrollToVacancy(e)}>Vacancy</a></li>
                    <li>
                      <a href="https://varnion.com">
                        <img src={homeLogo}></img>
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
              
              {
                this.props.contents.landing ?
                this.props.contents.landing.map((item, index)=>{
                  return (
                    <div key={item.id + ' ' + index} className="landing-banner" style={{backgroundImage: 'url(' + uploadUrl + item.image +')'}}>
                      <div className="landing-banner-content" dangerouslySetInnerHTML={{__html: item.caption}}></div>
                    </div>
                  )
                }) : null
              }

              {
                this.props.contents.banners ? 
                <div className="explore-dream" style={{backgroundImage: 'url(' + uploadUrl + this.props.contents.banners[0].image +')'}}>
                  <div className="explore-dream-content" dangerouslySetInnerHTML={{__html: this.props.contents.banners[0].caption}}></div>
                </div> : null
              }
              
              <div className="vcc-top">
                <div className="vcc-top-content">
                  <h2>VARNION CORPORATE CULTURE</h2>
                  <p>Our Values Make Us Be The WOW Company.</p>
                </div>
              </div>

              {
                this.props.contents.vcc ? 
                <div className="vcc-list">
                  <VCC vccItem={this.props.contents.vcc} uploadUrl={uploadUrl} gotoVCC={(e)=>this.gotoVCC(e)}/>
                </div> : null
              }
              
              {
                this.props.contents.banners ? 
                <Aux>
                  <div className="our-future-img" style={{backgroundImage: 'url('+ uploadUrl + this.props.contents.banners[1].image +')'}}></div>
                  <div className="our-future">
                    <div className="our-future-content" dangerouslySetInnerHTML={{__html: this.props.contents.banners[1].caption}}></div>
                  </div>
                </Aux> : null
              }
              
              <div className="vacancy" ref={this.vacancyRef}>
                <Vacancy onSearch={this.onSearchHandler} onInterest={(e)=>this.onInterest(e)} />
              </div>
            </div>
          )}
        </Transition>
      </Aux>
    )
  }
}

const mapStateToStore = (state) =>{
  return {
    vcc: state.base.vcc,
    loadingBar : state.base.loadingBar,
    loadingState: state.base.loading,
    contents: state.base.contents
  }
}

const mapDispatchToStore = (dispatch) => {
  return {
    scroll: (payload) => {
      dispatch(baseActions.scrollVacancy(payload))
    },
    filterVacancy : (search) => {
      dispatch(baseActions.filteredVacancy(search))
    },
    onInitContent: ()=>dispatch(actions.initHomeContent()),
    onInitVacancy: ()=> dispatch(actions.initVacancy()),
    setTestDone: (fieldTest, state)=>(actions.setTestDone(fieldTest, state))
  }
}

export default connect(mapStateToStore, mapDispatchToStore)(home);