import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

// store purpose
import {connect} from 'react-redux'
import * as actions from '../../store/actions/index'

//animation
import Transition from 'react-transition-group/Transition'

// style
import classes from './Interest.module.scss'

// img
import plus from '../../assets/img/icon-plus.png'
import arrow2 from '../../assets/img/arrow-2.png'
import blueArrow from '../../assets/img/blue-arrow.png'

// component
import Preview from '../../components/Interest/FilePreview/FilePreview'



class Interest extends Component {

  state={
    initial: false,
    startDate: new Date(),
    modalSelection: false,
    selectionData: [
      {id: 1, name: "Friends"},
      {id: 2, name: "Social Media"},
      {id: 3, name: "Websites"},
      {id: 4, name: "Other"}
    ],


    dataUserInterest:{
      slug: "",
      email:"",
      phone:"",
      expectedSalary: "",
      name:{
        first: "",
        last:""
      },
      highSchool:{
        name:"",
        location: "",
        year: ""
      },
      university:[
        {
          name:"",
          location:"",
          year:""
        },
        {
          name:"",
          location:"",
          year:""
        }
      ],
      workExperience:[
        {
          name:"",
          position:"",
          year:""
        },
        {
          name:"",
          position:"",
          year:""
        },
        {
          name:"",
          position:"",
          year:""
        }
      ],
      knowBy: "Choose Option..",
      start: new Date(),
    },
    provideEducation: false,
    provideExperience: false,
    attachment:{
      cv: {
        file: null,
        preview: null
      },
      avatar:{
        file: null,
        preview: null
      },
      other: null
    }
    // attachment: null
  }

  onSubmit = (event) => {
    event.preventDefault();
    console.log(this.state.dataUserInterest);
    this.props.storeFormProfile(this.state.dataUserInterest);
  }

  handleChange = (e, method , key, indexArray) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (method)
    {
      // phone, email, salary
      case 'single' :
        this.setState(state=> ({
          ...state,
          dataUserInterest: {
            ...state.dataUserInterest,
            [name]: value
          }
        }))
        break;

      // name, highschool
      case 'array' :
        this.setState(state=> ({
          ...state,
          dataUserInterest:{
            ...state.dataUserInterest,
            [name]:{
              ...state.dataUserInterest[name],
              [key] : value
            }
          }
        }))
        break;

      // univ, experience
      case 'arrObj' : 
        // let updateArray = this.state.dataUserInterest[name][indexArray]
        // updateArray[key] = value
        // console.log(updateArray);
        
        // ori
        // return this.setState(state=>({
        //   ...state,
        //   dataUserInterest:{
        //     ...state.dataUserInterest,
        //     [name]:{
        //       ...state.dataUserInterest[name],
        //       [indexArray]:{
        //         ...state.dataUserInterest[name][indexArray],
        //         [key] : value
        //       }
        //     }
        //   }
        // }))

        // modif
        this.setState({
          dataUserInterest:{
            ...this.state.dataUserInterest,
            [name]: {
              ...this.state.dataUserInterest[name],
              [indexArray]:{
                ...this.state.dataUserInterest[name][indexArray],
                [key] : value
              }
            }
          }
        })
        break;
      default :
        break;
    }
  }

  addInput = (e, field) =>{
    e.preventDefault();
    console.log(field);
    console.log(this.state.dataUserInterest[field].length);
    
    let newArray = []
    
    switch(field)
    {
      case 'university':
        newArray = [
          {
            name:"",
            location:"",
            year:""
          }
        ]
        break;
      case 'workExperience':
        newArray = [
          {
            name:"",
            position:"",
            year:""
          }
        ]
        break;
      default: break;
    } 
    // ori
    // this.setState((state)=>({
    //   ...state,
    //   dataUserInterest:{
    //     ...state.dataUserInterest,
    //     [field]:[
    //       ...state.dataUserInterest[field],
    //       ...newArray
    //       ]
    //     }
    //   })
    // )

    // modif
    this.setState({
      dataUserInterest:{
        ...this.state.dataUserInterest,
        [field]:[
          ...this.state.dataUserInterest[field],
          ...newArray
        ]
      }
    })

    console.log(this.state.dataUserInterest[field]);
    
  }

  handleChangeDate = (date) => {
    this.setState(state => ({
      ...state,
      dataUserInterest: {
        ...state.dataUserInterest,
        start : date
      }
    }))
  }
  
  switchEducation = (data)=>{
    this.setState({
      provideEducation: data
    })
  }

  switchExperience = (data)=>{
    this.setState({
      provideExperience: data
    })
  }

  switchModalSelection = (event)=>{
    event.preventDefault();
    
    this.setState({
      modalSelection: !this.state.modalSelection
    })
  }

  selectData = (data)=>{
    this.setState(state=>{
      state.dataUserInterest.knowBy = data
    })
    this.closeModal();
  }

  closeModal=()=>{
    this.setState({
      modalSelection: false
    })
  }

  singlePreview = (preview) =>{
    return new Promise((resolve, reject)=>{
      let reader = new FileReader();
      reader.readAsDataURL(preview);
      setTimeout(()=>{
        resolve(reader.result)
      }, 100)
    })
  }

  selectResume=()=>{
    this.fileResume.click()
  }
  selectAvatar=()=>{
    this.fileAvatar.click()
  }

  addResume=(event)=>{
    const file = event.target.files[0];
    const typeFile = file.type.split('/').shift();

    if(typeFile === "image")
    {
      this.singlePreview(file)
      .then(res=>{
        this.setState({
          attachment : {
            ...this.state.attachment,
            cv : {
              file: file,
              preview: res
            }
          }
        })
      })
    }
    if(typeFile === "application")
    {
      this.setState({
        attachment : {
          ...this.state.attachment,
          cv : {
            file: file,
            preview: "pdf"
          }
        }
      })
    }
  }

  addAvatar=(event)=>{
    const file = event.target.files[0];

    this.singlePreview(file)
    .then(res=>{
      this.setState({
        attachment : {
          ...this.state.attachment,
          avatar : {
            file: file,
            preview: res
          }
        }
      })
    })
  }

  componentDidMount()
  {
    window.scrollTo(0, 0);

    this.setState({
      initial: true
    })

    // if(this.props.interestVacancy === null)
    // {
    //   this.props.history.replace( '/');
    // }
    // if(this.props.interestVacancy !== null)
    // {
    //   let queryParams = []
    //   queryParams.push(encodeURIComponent(this.props.interestVacancy))
      
    //   let queryString = queryParams.join('&')
    //   this.props.history.replace({
    //     pathname: '/interest',
    //     hash: queryString
    //   })

    //   const slug = this.props.history.location.hash.split('#').pop();
    //   this.setState({
    //     dataUserInterest : {
    //       ...this.state.dataUserInterest,
    //       slug : slug
    //     }
    //   })
    // }
  }

  render() {
    return (
      <Transition in={this.state.initial} timeout={0} mountOnEnter unmountOnExit>
        {
          state=>(
            <div className={classes.interestForm} style={{opacity: state === 'entering'? 0 : 1, transitionDuration: '1s'}}>
              <Preview cv={this.state.attachment.cv.preview} avatar={this.state.attachment.avatar.preview} />
              <form onSubmit={this.onSubmit}>
                <div className={classes.section + ' ' + classes.upload}>
                  {
                    this.state.attachment.cv.file != null ?
                    null
                    : 
                    <div className={classes.btnUpload}>
                      <p className={classes.info}>Upload Resume</p>
                      <input 
                        type="file" 
                        name="file" 
                        accept="image/*, application/pdf" 
                        ref={fileResume=> this.fileResume = fileResume} 
                        onChange={this.addResume} />
                      <span className={classes.btnContainer}>
                        <button onClick={this.selectResume} style={{backgroundImage: 'url('+ plus +')'}}></button>
                      </span>
                    </div>
                  }
                  {
                    this.state.attachment.avatar.file == null && this.state.attachment.cv.file != null ?
                    <div className={classes.btnUpload}>
                      <p className={classes.info}>Upload Avatar</p>
                      <input 
                        type="file" 
                        name="file" 
                        accept="image/*" 
                        ref={fileAvatar=> this.fileAvatar = fileAvatar} 
                        onChange={this.addAvatar} />
                      <span className={classes.btnContainer}>
                        <button onClick={this.selectAvatar} style={{backgroundImage: 'url('+ plus +')'}}></button>
                      </span>
                    </div>
                    : 
                    null
                  }
                </div>

                <div className={classes.section}>
                  <h4>Name</h4>
                  <span>
                    <input name="name" onChange={(e)=>this.handleChange(e, 'array', 'first')} type="text" placeholder="First" />
                    <input name="name" onChange={(e)=>this.handleChange(e, 'array', 'last')} type="text" placeholder="Last" />
                  </span>
                </div>
                <div className={classes.email + ' ' + classes.section}>
                  <h4>Email</h4>
                  <span>
                    <input name="email" onChange={(e)=>this.handleChange(e, 'single')} type="email" placeholder="Email Address" />
                  </span>
                </div>
                <div className={classes.section + ' ' + classes.phone}>
                  <h4>Phone</h4>
                  <span>
                    <input name="phone" onChange={(e)=>this.handleChange(e, 'single')} type="text" placeholder="Phone Number" />
                  </span>
                </div>
                <div className={classes.section}>
                  <h4>Personal Information</h4>
                  <p>DO YOU PROVIDE LAST EDUCATION INFORMATION IN YOUR RESUME?</p>
                  <ul className={classes.selectionRadio}>
                    <li>
                      <input type="radio" id="t-edu-option" name="education" />
                      <label onClick={(e, state)=>this.switchEducation(true)}  htmlFor="t-edu-option">Yes</label>
                      <div className={classes.check}></div>
                    </li>
                    <li>
                      <input type="radio" defaultChecked id="f-edu-option" name="education" />
                      <label onClick={()=>this.switchEducation(false)}  htmlFor="f-edu-option">No</label>
                      <div className={classes.check}></div>
                    </li>
                  </ul>
                </div>
                <div className={classes.section + ' ' + classes.highSchool + ' ' + [this.state.provideEducation ? "" : classes.show]} >
                  <h4 className={classes.semiBold}>**High School</h4>
                  <span>
                    <input name="highSchool" onChange={(e)=>this.handleChange(e, 'array', 'name')} type="text" placeholder="Name" />
                    <input name="highSchool" onChange={(e)=>this.handleChange(e, 'array', 'location')} type="text" placeholder="Location" />
                    <input name="highSchool" onChange={(e)=>this.handleChange(e, 'array', 'year')} type="text" placeholder="Year" />
                  </span>
                </div>
                <div className={classes.section + ' ' + classes.university + ' ' + [this.state.provideEducation ? "" : classes.show]} >
                  <h4 className={classes.semiBold}>**University</h4>
                  {
                    Object.keys(this.state.dataUserInterest.university).map(index=>{
                      return(
                        <span key={["hei" + index]}>
                          <input name="university" onChange={(e)=>this.handleChange(e, 'arrObj', 'name', index)} type="text" placeholder="Name" />
                          <input name="university" onChange={(e)=>this.handleChange(e, 'arrObj', 'location', index)}  type="text" placeholder="Location" />
                          <input name="university" onChange={(e)=>this.handleChange(e, 'arrObj', 'year', index)} type="text" placeholder="Year" />
                          <span className={classes.btnContainer}>
                            <button onClick={(e)=>this.addInput(e, 'university')} style={{backgroundImage: 'url('+ plus +')'}}></button>
                          </span>
                          {/* {
                            index == this.state.dataUserInterest.university.length - 1 && this.state.dataUserInterest.university.length < 2 ? 
                            <span className={classes.btnContainer}>
                              <button onClick={(e)=>this.addInput(e, 'university')} style={{backgroundImage: 'url('+ plus +')'}}></button>
                            </span>
                            : null
                          }   */}
                        </span>
                      )
                    })
                  }
                </div>
                <div className={classes.section}>
                  <p>DO YOU PROVIDE WORK EXPERIENCE INFORMATION IN YOUR RESUME?</p>
                  <ul className={classes.selectionRadio}>
                    <li onClick={()=>this.switchExperience(true)}>
                      <input type="radio" id="t-exp-option" name="experience" />
                      <label htmlFor="t-exp-option">Yes</label>
                      <div className={classes.check}></div>
                    </li>
                    <li onClick={()=>this.switchExperience(false)}>
                      <input type="radio" defaultChecked id="f-exp-option" name="experience" />
                      <label htmlFor="f-exp-option">No</label>
                      <div className={classes.check}></div>
                    </li>
                  </ul>
                </div>
                <div className={classes.section + ' ' + classes.experience + ' ' + [this.state.provideExperience ? "" : classes.show]}>
                  <h4 className={classes.semiBold}>**Work Experience</h4>
                  {
                    Object.keys(this.state.dataUserInterest.workExperience).map(index=>{
                      return(
                        <span key={index}>
                          <input name="workExperience" onChange={(e)=>this.handleChange(e, 'arrObj', 'name', index)} type="text" placeholder="Name" />
                          <input name="workExperience" onChange={(e)=>this.handleChange(e, 'arrObj', 'position', index)} type="text" placeholder="Position" />
                          <input name="workExperience" onChange={(e)=>this.handleChange(e, 'arrObj', 'year', index)} type="text" placeholder="Year" />
                          {/* <span className={classes.btnContainer}>
                            <button onClick={(e)=>this.addInput(e, 'workExperience')} style={{backgroundImage: 'url('+ plus +')'}}></button>
                          </span> */}
                          {/* {
                            index == [this.state.dataUserInterest.workExperience.length - 1] ? 
                            <span className={classes.btnContainer}>
                              <button onClick={(e)=>this.addInput(e, 'workExperience')} style={{backgroundImage: 'url('+ plus +')'}}></button>
                            </span>
                            : null
                          } */}
                        </span>
                      )
                    })
                  }
                </div>
                <div className={classes.section + ' ' + classes.howKnow} onMouseLeave={()=>this.closeModal()}>
                  <h4>How Did You Know About Us ?</h4>
                  <span>
                    <p onClick={(e)=>this.switchModalSelection(e)}>{this.state.dataUserInterest.knowBy}<span className={classes.arrow} style={{backgroundImage: 'url('+ arrow2 +')'}}></span></p>
                    <ul className={this.state.modalSelection ? classes.show : ''}>
                      {this.state.selectionData.map((item)=>(
                        <li onClick={()=>this.selectData(item.name)} key={item.id}>{item.name}</li>
                      ))}
                      <span className={classes.blueArrow} style={{backgroundImage: 'url('+ blueArrow +')'}}></span>
                  </ul>
                  </span>
                  
                </div>
                <div className={classes.section + ' ' + classes.start}>
                  <h4>When Could You Start</h4>
                  <span className={classes.datePicker}>
                    <DatePicker
                      selected={this.state.dataUserInterest.start}
                      onChange={this.handleChangeDate}
                    />
                  </span>
                </div>
                <div className={classes.section + ' ' + classes.salary}>
                  <h4>Expected Salary</h4>
                  <span>
                    <input name="expectedSalary" onChange={(e)=>this.handleChange(e, 'single')} type="text" />
                  </span>
                </div>
                <div className={classes.section + ' ' + classes.btnContainer}>
                  <button>Submit</button>
                </div>
              </form>
            </div>
          )
        }
      </Transition>
    )
  }
}

const mapStateToStore = (state) => {
  return{
    interestVacancy: state.base.interestVacancy,
    dataApplicant: state.base.dataUserInterest,
  }
}

const mapDispatchToStore = (dispatch) => {
  return {
    onInterestVacancy: (item)=> dispatch(actions.fillInterest(item)),
    storeFormProfile: (data)=>dispatch(actions.storeFormProfile(data)),
    
  }
}

export default connect(mapStateToStore, mapDispatchToStore)(Interest);