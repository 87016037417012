export const updateObject = (oldObject, updatedObject) =>{
  return{
    ...oldObject,
    ...updatedObject
  }
}

export const checkValidity = ( value, rules ) => {
  let isValid = true;
  if ( !rules ) {
      return true;
  }

  if ( rules.required ) {
      isValid = value.trim() !== '' && isValid;
  }

  if ( rules.minLength ) {
      isValid = value.length >= rules.minLength && isValid
  }

  if ( rules.maxLength ) {
      isValid = value.length <= rules.maxLength && isValid
  }

  if ( rules.isEmail ) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test( value ) && isValid
  }

  if ( rules.isNumeric ) {
      const pattern = /^\d+$/;
      isValid = pattern.test( value ) && isValid
  }

  if ( rules.isCurrency ) {
    const pattern = /^\d+$/;
    isValid = pattern.test( value ) && isValid
}

  return isValid;
}

export const updateFeedback = (value, valid, fieldname) =>{
  let newFeedback = "This field is required";
  const pattern = /^\d+$/;
  const numeric = pattern.test( value );
      
  
  switch(fieldname)
  {
    case 'email' : 
      if(value.length > 0 && !valid)
      {
        newFeedback = "There’s something wrong with your email. Check if you typed it in correctly. Perhaps you missed \".\" or \"@\" somewhere?"
      }
      else
      {
        newFeedback = "This field is required";
      }
      break;
    case 'phone' :
      if(value.length > 0 && !valid)
      {
        newFeedback = "The phone number must be numeric"
      }
      else
      {
        newFeedback = "This field is required";
      }
      break;
    case 'highschoolYear' :
      if(value.length > 0 && !numeric)
      {
        newFeedback = "The year must be numeric"
      }
      else if(numeric && value.length < 4)
      {
        newFeedback = "Please input the valid year, Ex: 2019"
      }
      else if(numeric && value.length > 4)
      {
        newFeedback = "Please input the valid year, Ex: 2019"
      }
      else
      {
        newFeedback = "This field is required";
      }
      break;
    case 'year' :
      if(value.length > 0 && !numeric)
      {
        newFeedback = "The year must be numeric"
      }
      else if(numeric && value.length < 4)
      {
        newFeedback = "Please input the valid year, Ex: 2019"
      }
      else if(numeric && value.length > 4)
      {
        newFeedback = "Please input the valid year, Ex: 2019"
      }
      else
      {
        newFeedback = "This field is required";
      }
      break;
    case 'salary' :
      if(value.length > 0 && !numeric)
      {
        newFeedback = "The salary must be numeric"
      }
      else
      {
        newFeedback = "This field is required";
      }
      break;
    default: break;
  }
  return newFeedback;
}

export const englishChecked = (answer) =>{
  let resAnswer = null;
  switch(answer)
  {
    case 'a': resAnswer = 0; break;
    case 'b': resAnswer = 1; break;
    case 'c': resAnswer = 2; break;
    case 'd': resAnswer = 3; break;
    default: break;
  }
  return resAnswer;
}