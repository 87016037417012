import * as actionTypes from '../actions/actionTypes'

import {updateObject} from '../utility'
// import Cookies from 'universal-cookie'

// const cookies = new Cookies();

const initialState = {
  interestVacancy: null,
  vcc: [
    {
      id: 1,
      content: "Delivering WOW Service to Everyone",
      img: 'http://career.varnion.com/betafront/asset/img/vcc-1.jpg'
    },
    {
      id: 2,
      content: "We Are Team and Family",
      img: 'http://career.varnion.com/betafront/asset/img/vcc-2.jpg'
    },
    {
      id: 3,
      content: "Work With Fun and Madness",
      img: 'http://career.varnion.com/betafront/asset/img/vcc-3.jpg'
    },
    {
      id: 4,
      content: "Satisfaction in Innovation",
      img: 'http://career.varnion.com/betafront/asset/img/vcc-4.jpg'
    },
    {
      id: 5,
      content: "Becoming Agent of Change",
      img: 'http://career.varnion.com/betafront/asset/img/vcc-5.jpg'
    },
    {
      id: 6,
      content: "Be Humble and Glorify God",
      img: 'http://career.varnion.com/betafront/asset/img/vcc-6.jpg'
    },
  ],
  loadingBar: 0,
  loading: true,
  contents: []
}

const setLoader = (state, action)=>
{
  if(action.amount === 50)
  {
    return updateObject(state, {loadingBar: state.loadingBar + action.amount})
  }
  return state;
}

const setStateLoader = (state, action)=>{
  if(state.loadingBar < 100)
  {
    return state;
  }
    return updateObject(state, {loading: action.state})
}

const setHomeContent = (state, action)=>{
  return updateObject(state, {contents : action.contents})
}

const scrollVacancy = (state, action)=>{
  // window.scrollTo(0, action.payload.topVacan - action.payload.navHeight)
  window.scrollTo({left : 0, top : action.payload.topVacan - action.payload.navHeight, behavior: 'smooth'})
  return state;
}

const fillInterest = (state, action)=>{
  // cookies.set('interest', action.payload, {path: '/'})
  localStorage.setItem('interest', action.payload);
    return{
      ...state,
      interestVacancy: action.payload
    }
}

const baseReducer = (state = initialState, action)=>{
  switch(action.type)
  {
    case actionTypes.SET_LOADER: return setLoader(state,action);
    case actionTypes.SET_STATE_LOADER: return setStateLoader(state,action);
    case actionTypes.SCROLL_VACANCY: return scrollVacancy(state,action);
    case actionTypes.FILL_INTEREST: return fillInterest(state,action);
    case actionTypes.SET_HOME_CONTENT: return setHomeContent(state,action);
    default: return state;
  }
}

export default baseReducer;