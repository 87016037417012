import React, { Component } from 'react'

// import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import {Route} from 'react-router-dom'
import classes from './Test.module.scss'
import {connect} from 'react-redux'

import Transition from 'react-transition-group/Transition'

// import Personality from './Personality/Personality'
import Personality2 from './Personality/Personality2'
import English from './English/English'
import Timer from '../../components/UI/Timer/Timer'
import Navbar from '../../components/Navbar/Navbar'
import Thankyou from '../../components/Thankyou/Thankyou'


class Test extends Component {
  state={
    initial: false
  }
  componentWillMount()
  {
    if(this.props.listQuestionPersonality === null && this.props.listQuestionPersonality === null)
    {
      this.props.history.replace( '/')
    }
  }
  componentDidMount()
  {
    this.setState({
      initial : true
    })
  }

  backHome=()=>
  {
    window.location.replace('/');
  }

  render() {
    return (
      <Transition in={this.state.initial} timeout={0} mountOnEnter unmountOnExit>
        {
            state=>(
              <div className={classes.test} style={{opacity: state === 'entering'? 0 : 1, transitionDuration: '1s'}}>
                <Navbar backHome={this.backHome} state={state} transparent={true}/>
                {
                  this.props.history.location.pathname === "/test/personality/intro" || 
                  this.props.history.location.pathname === "/test/english/intro" || 
                  this.props.history.location.pathname === "/test/thankyou" ||
                  this.props.formSubmitting ?
                  null: 
                  <Timer timer={this.props.timer} />
                }
                <div className={classes.top}></div>
                <div className={classes.testContainer}>
                  <Route path={`${this.props.match.path}/personality`} component={Personality2} />
                  <Route path={`${this.props.match.path}/english`} component={ English } />
                  <Route path={`${this.props.match.path}/thankyou`} exact component={ Thankyou } />
                </div>
              </div>
          )
        }

      </Transition>
    )
  }
}

const mapStateToStore = (state) =>{
  return{
    listQuestionPersonality: state.test.listQuestionPersonality,
    listQuestionEnglish: state.test.listQuestionEnglish,
    timer: state.test.timer.counter,
    formSubmitting: state.test.submitting
  }
}

export default connect(mapStateToStore)(Test);