import React, { Component } from 'react'

import classes from './Upload.module.scss'
import Aux from '../../../hoc/Aux'

// img
import plus from '../../../assets/img/icon-plus.png'
class Upload extends Component {
  selectResume=(e)=>{
    e.preventDefault();
    this.fileResume.click()
  }
  selectAvatar=(e)=>{
    e.preventDefault();
    this.fileAvatar.click()
  }
  selectAttachment=(e, id)=>{
    e.preventDefault();
    this["attachment"+id].click()
  }

  addFile=(e, method, index)=>{
    const file = e.target.files[0];
    // console.log(file);
    
    setTimeout(()=>{
      this.props.addFile(file, method, index)
    },100)
  }

  render() {
    return (
      <div className={classes.section + ' ' + classes.upload}>
        {
          this.props.cv.file === null ?
          <Aux>
            <div className={classes.btnUpload}>
              <p className={classes.info}>Upload Resume</p>
              <input 
                type="file" 
                name="file" 
                accept="image/*, application/pdf" 
                ref={fileResume=> this.fileResume = fileResume} 
                onChange={(e)=>this.addFile(e, "resume")} />
              <span className={classes.btnContainer}>
                <button onClick={(e)=>this.selectResume(e)} style={{backgroundImage: 'url('+ plus +')'}}></button>
              </span>
            </div>
            {
              this.props.cv.feedback ?
              <p className={classes.note}>{this.props.cv.feedback}</p>: 
              <p className={classes.note}>*max upload file 2Mb</p>
            }
          </Aux>
          : null
        }
        {
          this.props.avatar.preview == null && this.props.cv.file != null ?
          <Aux>
            <div className={classes.btnUpload}>
              <p className={classes.info}>Upload Avatar</p>
              <input 
                type="file" 
                name="file" 
                accept="image/*" 
                ref={fileAvatar=> this.fileAvatar = fileAvatar} 
                onChange={(e)=>this.addFile(e, "avatar")} />
              <span className={classes.btnContainer}>
                <button onClick={(e)=>this.selectAvatar(e)} style={{backgroundImage: 'url('+ plus +')'}}></button>
              </span>
            </div>
            {
              this.props.avatar.feedback ?
              <p className={classes.note}>{this.props.avatar.feedback}</p>: 
              <p className={classes.note}>*max upload file 2Mb</p>
            }
          </Aux>
          : 
          null
        }
        {
          this.props.cv.file != null && this.props.avatar.preview != null ?
          <Aux>
            {
              this.props.otherAttachment.map((item,index)=>{
                return (
                  <Aux>
                    {
                      item.preview === null ?
                      <Aux key={"other"+index}>
                        <div className={classes.btnUpload}>
                          <p className={classes.info}>Upload Other File ({index+1})</p>
                          <input 
                            type="file" 
                            name="file" 
                            accept="image/*, application/pdf" 
                            ref={attachment=> this["attachment" + index] = attachment} 
                            onChange={(e)=>this.addFile(e, "attachment", index)} />
                          <span className={classes.btnContainer}>
                            <button onClick={(e)=>this.selectAttachment(e, index)} style={{backgroundImage: 'url('+ plus +')'}}></button>
                          </span>
                        </div>
                        {
                          item.feedback ?
                          <p className={classes.note}>{item.feedback}</p> : 
                          <p className={classes.note + " " + classes.optional}>*this field is optional</p>
                        }
                      </Aux> : null
                    }
                  </Aux>
                )
              })
            }
          </Aux>
          : 
          null
        }
      </div>
    )
  }
}

export default Upload;
